import React, { useState, } from "react";
import {
  handleNetworkError
} from "../../../../utils/utils";
import { useHistory } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { notifyOk, notifyError, notifyInfo } from "../../../Misc/InfoItems/toasts";
import WhatssapButton from "../../../Misc/WhatssapButton";
import "react-toastify/dist/ReactToastify.css";
import {
  Row, Col, Form, FormGroup, Input
} from "reactstrap";
import eye from "../../../../Assets/show_password.png";
import hideEye from "../../../../Assets/hidden_password.png";
import { _updatePasswd } from '../../../../Controllers/usuarioCtl';

const ActualizarPasswdPage = () => {
  const history = useHistory();
  const [currentPasswd, setCurrentPasswd] = useState('');
  const [passwd, setPasswd] = useState('');
  const [confPasswd, setConfPasswd] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);


  function chkPasswd() {
    let passwordValidate =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@?$%^&*()_+])[0-9a-zA-Z!@?$%^&*()_+]{12,30}$/.test(
        passwd
      );
    if (passwordValidate) {
      document.getElementById("messagepasslength").style.display = "none";
    } else {
      document.getElementById("messagepasslength").innerHTML =
        "La contraseña debe tener un mínimo de 12 caracteres, una letra mayúscula, un número y cualquiera de estos caracteres _&?@^*%!";
    }
  }

  //Confirm password
  const checkConfirmPassword = () => {
    if (passwd !== confPasswd) {
      document.getElementById("messagePasswordDontMatch").innerHTML =
        "Las contraseñas no coinciden";
    } else {
      document.getElementById("messagePasswordDontMatch").style.display =
        "none";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentPasswd || !passwd || !confPasswd) {
      notifyInfo('Por favor, ingrese la información solicitada')
      return;
    }

    const data = {
      current_password: currentPasswd,
      password_input: passwd,
      conf_password: confPasswd
    };

    try {
      await _updatePasswd(data);
      notifyOk("Contraseña actualizada");
      setCurrentPasswd('');
      setPasswd('');
      setConfPasswd('');
    } catch (error) {
      handleNetworkError(error, history);
      if (error.response) {
        if (error.response.status === 403 && error.response.data.data === 'PASSWORD_INCORRECTO') {
          notifyError('Contraseña original incorrecta');
        }
        else if (error.response.status === 400) {
          notifyError('Nueva contraseña es inválida. Asegúrese de usar letras, números y/o estos símbolos ($_&,=?@^*)%!)',
            { autoClose: 6000 });
        }
      }
    }
  };

  const togglePassword = () => {
    // When the handler is invoked
    // chnage inverse the boolean state passwordShown
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <ToastContainer />
      <label className="page-top-title">Cambiar contraseña</label>
      <Row className="mt-3">
        <Col md="12" lg="6" style={{ margin: '0 auto' }}>
          <div className="simple-card-exp">
            <Form onSubmit={handleSubmit} style={{ margin: '1rem 0' }}>
              <FormGroup>
                <Input
                  type="password"
                  id="currentPasswd"
                  value={currentPasswd}
                  placeholder="Contraseña actual"
                  onChange={(e) => setCurrentPasswd(e.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <Input
                  maxLength="30"
                  minLength="12"
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  value={passwd}
                  placeholder="Nueva contraseña"
                  onChange={(e) => setPasswd(e.target.value)}
                  onKeyUp={chkPasswd}
                />
                <span onClick={togglePassword} className="show-password">
                  <img
                    style={{ height: "20px" }}
                    src={passwordShown ? eye : hideEye}
                    alt="show password"
                  />
                </span>
              </FormGroup>
              <div className="mb-2">
                <span style={{ color: "red" }} id="messagepasslength"></span>
              </div>

              <FormGroup>
                <Input
                  maxLength="30"
                  minLength="12"
                  type="password"
                  id="confirmPassword"
                  value={confPasswd}
                  placeholder="Confirmar nueva contraseña"
                  onKeyUp={checkConfirmPassword}
                  onChange={(e) => setConfPasswd(e.target.value)}
                />
              </FormGroup>
              <div className="mb-2">
                <span id="message"></span>
              </div>
              <button className="btonbilletera bton-yellow">Guardar</button>
            </Form>
          </div>
        </Col>
      </Row>
      <WhatssapButton />
    </>
  );
};

export default ActualizarPasswdPage;
