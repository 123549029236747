import React from "react";
import { Fade } from "react-awesome-reveal";
import "./contact.css";

export const Contact = () => {
  return (
    <section className="contact mt-5">
      <h2 className="title">CONTACTO</h2>
      <div className="te-container-contact" id="contacto">
        <Fade left style={{ width: "100%" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1410.601054577236!2d-78.48587246845841!3d-0.24351513458531474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d59941394f5439%3A0x811ea8d9985768fe!2sTAGEXPRESS!5e0!3m2!1sen!2sec!4v1614883109232!5m2!1sen!2sec"
            width="100%"
            className="te-contact-map"
            frameBorder="0"
            aria-hidden="false"
            tabIndex="0"
            title="mapTagExpress"
          ></iframe>
        </Fade>
        <Fade right style={{ width: "100%" }}>
          <div className="te-contact-info">
            <h3 className="title">Información</h3>
            <p className="paragraph">
              Actuamos con honestidad y transparencia, Ofrecemos calidad en
              nuestro producto y servicio.
            </p>
            <h3 className="subtitle">Dirección:</h3>
            <p className="paragraph">Peaje Autopista General Rumiñahui</p>
            <h3 className="subtitle">Teléfonos:</h3>
            <p className="paragraph">098-331-8863</p>
            <h3 className="subtitle">Correo electrónico:</h3>
            <p className="paragraph">servicioalcliente@tagexpress.club</p>
          </div>
        </Fade>
      </div>
    </section>
  );
};
