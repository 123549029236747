import React from "react";
import { Puff } from "react-loader-spinner";
import "./theLoader.css";

const TheLoader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <Puff color="#ffe66d" height={100} width={100} />
      </div>
    </div>
  );
};

export default TheLoader;
