import NavLayout from "../../Layout/Nav";
import { Footer } from "../publicLanding/components";
import EndCallMale from "../../../Assets/End-Call-Male.png";
import LocationImg from "../../../Assets/location.png";
import CalendarImg from "../../../Assets/schedule.png";
import Tollbooth from "../../../Assets/tollbooth.png";
import BrakeWarning from "../../../Assets/brake-warning.png";
import BannerImg from "../../../Assets/banner-vias.jpg";
import Attention from "../../../Assets/svg/attention.svg";
import "./RoadConditions.css";
import { useEffect, useState } from "react";
import { _getEstadoViasData } from "../../../Controllers/RoadConditionsCtl";
import { handleNetworkError } from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import { minutosATiempo } from "../../../utils/minutosTiempo";
import { MapView } from "../../Misc/MapView/MapView";
import HeaderPage from "../../Misc/HeaderPage/HeaderPage";

function RoadConditions() {
  const history = useHistory();

  const contact = [
    {
      id: 1,
      name: "Peaje Colimes",
      phones: ["0991712540"],
    },
    {
      id: 2,
      name: "Peaje Cadena",
      phones: ["0991709689"],
    },
    {
      id: 3,
      name: "Peaje Chiveria",
      phones: ["0991706047"],
    },
    {
      id: 4,
      name: "Peaje Yaguachi",
      phones: ["0991778937"],
    },
    {
      id: 5,
      name: "Peaje Samborondón",
      phones: ["0991708436"],
    },
    {
      id: 6,
      name: "Peaje Daule",
      phones: ["0939384679"],
    },
    {
      id: 7,
      name: "Peaje Guayas Norte",
      phones: ["0991706873"],
    },
    {
      id: 8,
      name: "Peaje Milagro",
      phones: ["0991778629"],
    },
    {
      id: 9,
      name: "Peaje Tambo",
      phones: ["0991712178"],
    },
    {
      id: 10,
      name: "Peaje Boliche",
      phones: ["0991775095"],
    },
    {
      id: 11,
      name: "Peaje Naranjal",
      phones: ["0991711910"],
    },
    {
      id: 12,
      name: "Peaje Triunfo",
      phones: ["0991707008"],
    },
    {
      id: 13,
      name: "Peaje Naranjito",
      phones: ["0991710815"],
    },
    {
      id: 14,
      name: "El Pan",
      phones: ["0991707851"],
    },
    {
      id: 15,
      name: "Peaje Alóag",
      phones: ["0992747857"],
    },
  ];

  const [data, setData] = useState({
    vias: [],
    loading: false,
  });

  const _getEstadoVias = async () => {
    try {
      const { data } = await _getEstadoViasData();

      setData((old) => ({
        ...old,
        vias: data,
        loading: false,
      }));
    } catch (error) {
      setData((old) => ({ ...old, loading: false }));
      handleNetworkError(error, history);
    }
  };
  useEffect(() => {
    _getEstadoVias();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <NavLayout />
      <HeaderPage urlImg={BannerImg} title="Estado de vías" />
      <div className="container" style={{ marginTop: 50 }}>
        {data.vias.length > 0 ? (
          <div className="container-card">
            {data.vias.map((via, index) => (
              <div className="card-vias" key={index}>
                <div className="content">
                  <img src={BrakeWarning} alt="" className="brake-warning" />
                  <h3 className="text-center pb-4" style={{ fontSize: "18px" }}>
                    {via.categoria_estado_peaje.descripcion}
                  </h3>
                  <div className="description-container">
                    <div className="description">
                      <img src={Tollbooth} alt="" />
                      <span>{via.peaje.descripcion}</span>
                    </div>
                    <div className="description">
                      <img src={CalendarImg} alt="" />
                      <span>
                        {via.tiempo_estimado
                          ? `Tiempor estimado ${minutosATiempo(
                              via.tiempo_estimado
                            )}`
                          : "-"}
                      </span>
                    </div>
                    <div className="description">
                      <img src={LocationImg} alt="" />
                      <span>{via.via || "-"}</span>
                    </div>
                  </div>
                </div>
                {via.lat && via.long && (
                  <MapView
                    lat={via.lat}
                    lng={via.long}
                    style={{
                      width: "100%",
                      height: 300,
                      borderRadius: "0px 0px 10px 10px",
                      zIndex: 0,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="no-estado-vias">
            <h2 className="text-center mb-5">
              Por el momento no existe ningún evento
            </h2>
            <img src={Attention} alt="Attention" />
          </div>
        )}
        <h2 className="title">
          ¡En caso de emergencia, comunícate con nosotros!
        </h2>
        <h2 className="title">LÍNEAS DE EMERGENCIA</h2>
        <div className="container-contact">
          {contact.map((item) => (
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
              key={item.id}
            >
              <img
                src={EndCallMale}
                alt="End Call Male"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <div>
                <h3
                  style={{
                    color: "var(--yellow)",
                    textTransform: "uppercase",
                    fontSize: "20px",
                  }}
                >
                  {item.name}
                </h3>
                {item.phones.map((phone, index) => (
                  <p
                    key={index}
                    style={{
                      margin: 0,
                      color: "#5B5B5B",
                      fontSize: "26px",
                      fontWeight: 600,
                    }}
                  >
                    {phone}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default RoadConditions;
