import React, { useCallback, useEffect, useState } from "react";
import { pubHeaders } from "../../../utils/utils";
import TheLoader from "../../../Components/Misc/TheLoader";
import api from "../../../services/api";
import checked from "../../../Assets/checked.png";
import cancelvalid from "../../../Assets/cancelvalid.png";
import { Link } from "react-router-dom";
import { notifyError, notifyInfo } from "../../Misc/InfoItems/toasts";
import logoTaxExpress from "../../../Assets/logo-sin-fondo-tag-express.png";
import "./confirmCuenta.css";
import {
  Input,
  Container,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
  Label,
} from "reactstrap";
import { ToastContainer } from "react-toastify";

const ConfirmCuenta = ({ history }) => {
  const [isValidated, setIsValidated] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [username, setUsername] = useState("");

  const userCode = history.location.pathname;


  const validateUser = useCallback(async () => {
    try {
      console.log('Si entre aqui, el codigo es valido');
      await api.post(
        `/api/usuarios/verificacion/${userCode.split("/")[4]}`,
        {},
        {
          headers: pubHeaders(),
        }
      );
      setIsValidated(true);
      console.log('Validate code ', isValidated);
    } catch (error) {
      setIsValidated(false);
    }
    setShowLoader(false);
  }, [isValidated, userCode]);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  async function resendMail() {
    if (!username) {
      notifyError("Ingrese su usuario");
      return;
    }

    setShowLoader(true);

    try {
      await api.post(`/api/usuarios/reenviarVerifEmail`, { username });

      let msgTime = 8000;
      notifyInfo(
        "Correo enviado. Revise su buzón o la sección de spam, por favor",
        { autoClose: msgTime }
      );
      setTimeout(() => {
        history.push("/");
      }, msgTime);
    } catch (e) {
      notifyInfo("Su cuenta ya fue verificada");
    }

    setShowLoader(false);
  }

  console.log('Validate code ', isValidated);

  return (
    <>
      <ToastContainer />
      {showLoader ? <TheLoader /> : <></>}
      {!isValidated ? (
        <div>
          <Container className='mt-5 container-style'>
            <Card>
              <CardHeader className="text-center">
                <img className="logo-center" src={logoTaxExpress} alt="logo" />
              </CardHeader>
              <CardBody className="text-center">
                <CardTitle tag="h2">
                  <strong>Tu cuenta ha sido validada</strong>
                </CardTitle>
                <CardText>
                  <img
                    src={checked}
                    alt="user validated"
                    style={{ height: 50, margin: "10px 0px" }}
                  />
                </CardText>
                <Link to="/login">
                  <div
                    className="bton bton-yellow bton-sm login-btn"
                    style={{ width: 350, fontSize: 14 }}
                  >
                    <strong>Regresar a pantalla de acceso</strong>
                  </div>
                </Link>
              </CardBody>
              <CardFooter className="text-center">
                Para cualquier duda, por favor comunicarse a este correo:{" "}
                <strong>servicioalcliente@tagexpress.club</strong>
              </CardFooter>
            </Card>
          </Container>
        </div>
      ) : (
        <div>
          <Container className='mt-5 container-style'>
            <Card>
              <CardHeader className="text-center">
                <img className="logo-center" src={logoTaxExpress} alt="logo" />
              </CardHeader>
              <CardBody className="text-center">
                <CardTitle tag="h2">
                  <strong>Link de confirmación ha caducado</strong>
                </CardTitle>
                <CardText>
                  <img
                    src={cancelvalid}
                    alt="Error"
                    style={{ height: 50, margin: "10px 0px" }}
                  />
                </CardText>
                <div className="resend-mail-wrapper">
                  <Label>
                    Ingrese su usuario para reenviarle el link de confirmación
                  </Label>
                  <Input
                    maxLength="50"
                    type="email"
                    placeholder="correo"
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>

                <div
                  onClick={resendMail}
                  className="bton bton-yellow bton-sm login-btn"
                  style={{ width: 150, fontSize: 14 }}
                >
                  <strong>Reenviar</strong>
                </div>
              </CardBody>
              <CardFooter style={{ textAlign: "center" }}>
                <Link to="/">
                  <label style={{ margin: 0 }} className="link">
                    Volver a pantalla de inicio
                  </label>
                </Link>
              </CardFooter>
            </Card>
          </Container>
        </div>
      )}
    </>
  );
};

export default ConfirmCuenta;
