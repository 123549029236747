import React from "react";

import "./Slideshow.css";

const Slideshow = ({ children, controles }) => {

  return (
    <div className="container-main text-dark">
      <div className="container-slideshow brandImg">
        {children}
      </div>
    </div>
  );
};

export { Slideshow };
