import React, { useState } from "react";
import api from "../../../../services/api";
import { ToastContainer } from "react-toastify";
import { notifyWarning, notifyError } from '../../../Misc/InfoItems/toasts';
import { pubHeaders } from "../../../../utils/utils";
import logoTaxExpress from "../../../../Assets/logo-sin-fondo-tag-express.png";
import hideEye from "../../../../Assets/hidden_password.png";
import eye from "../../../../Assets/show_password.png";
import {
  Container,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

const PasswdRecUpdate = ({ history }) => {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [passwordShown, setPasswordShown] = useState(false);


  const verificationCode = history.location.pathname;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      password_input: password,
      conf_password: confirmPassword,
    };
    try {
      const response = await api.post(
        `/api/usuarios/passwd/verificacion/${verificationCode.split("/")[4]}`,
        data,
        {
          headers: pubHeaders(),
        }
      );
      if (response) {
        let successMsgTime = 2000;
        notifyWarning("Recuperación de contraseña satisfactoria", { autoClose: successMsgTime });
        setTimeout(() => {
          history.push('/login');
        }, successMsgTime);
      }
    } catch (error) {
      notifyError("Error al enviar el formulario");
    }
  };

  const checkPassworValid = (password) => {
    setPassword(password)
    let passwordValidate = /^[0-9a-zA-Z$_&,=?@^*)%!]{12,30}$/.test(password);
    if (passwordValidate) {
      document.getElementById("messagepasslength").style.display = "none";
    } else {
      document.getElementById("messagepasslength").style.color = "red";
      document.getElementById("messagepasslength").innerHTML =
        "La contraseña debe tener un mínimo de 12 caracteres, una letra mayúscula, un número y cualquiera de estos caracteres _&,=?@^*)%!";
    }
  };

  //Confirm password
  const check = () => {
    if (password !== confirmPassword) {
      // alert("las contrasenas no coinciden")
      document.getElementById("message").style.color = "red";
      document.getElementById("message").innerHTML =
        "Las contraseñas no coinciden";
    } else {
      document.getElementById("message").style.display = "none";
    }
    // console.log("Contrasena: ", password);
  };

  const togglePassword = () => {
    // When the handler is invoked
    // chnage inverse the boolean state passwordShown
    setPasswordShown(!passwordShown);
  };
  return (
    <>
      <ToastContainer />
      <div className="screen-container">
        <Container>
          <Card>
            <CardHeader>
              <img className="centerImgTag" style={{ height: 50 }} src={logoTaxExpress} alt="logo" />
            </CardHeader>
            <CardBody className="text-center">
              <CardTitle tag="h2">
                <strong>Restauración de contraseña</strong>
              </CardTitle>
              <CardText>
                <strong>Por favor ingresa tu nueva contraseña</strong>
              </CardText>
              <div className="passwdForm">
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Input
                      maxLength="30"
                      minLength="12"
                      type={passwordShown ? "text" : "password"}
                      id="password"
                      placeholder="Contraseña"
                      onKeyPress={(e) => checkPassworValid(e.target.value)}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <span onClick={togglePassword} className="show-password"><img style={{ height: "20px" }} src={passwordShown ? eye : hideEye} alt="show password" /></span>
                  </FormGroup>

                  <div className="mb-2">
                    <span id="messagepasslength"></span>
                  </div>

                  <FormGroup>
                    <Input
                      maxLength="30"
                      minLength="12"
                      type={passwordShown ? "text" : "password"}
                      id="confirmPassword"
                      placeholder="Confirmar contraseña"
                      onBlur={check}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <div className="mb-2">
                    <span id="message"></span>
                  </div>

                  <button
                    style={{ cursor: "pointer" }}
                    className="bton bton-yellow bton-sm login-btn"
                  >
                    Restaurar contraseña
                  </button>
                </Form>
              </div>
            </CardBody>
            <CardFooter className="text-center">
              Para cualquier duda o problema en el proceso por favor comunicarse
              a este correo
              <strong> servicioalcliente@tagexpress.club</strong>
            </CardFooter>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default PasswdRecUpdate;
