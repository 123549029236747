import React, { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import "./aboutUs.css";
import ICMision from "../../../../../Assets/svg/ic_mision.svg";
import ICVision from "../../../../../Assets/svg/ic_vision.svg";
import ICValues from "../../../../../Assets/svg/ic_values.svg";

export const AboutUs = () => {
  const dataCard = [
    {
      title: "Misión",
      content:
        "Facilitar la movilidad, Marcar la diferencia al conectar estaciones de peaje, Garantizar accesibilidad a los servicios, Ofrecer la mejor experiencia al facilitar al usuario revisar su gestión de cuenta, Brindar seguridad con el servicio de Check Point.",
      img: ICMision,
    },
    {
      title: "Visión",
      content:
        "Ser la mejor empresa en ofrecer soluciones y servicios con innovación y calidad, ser reconocidos como una empresa con servicios de calidad, excelencia e integralidad.",
      img: ICVision,
    },
    {
      title: "Valores",
      content:
        "Actuamos con honestidad y transparencia, Ofrecemos calidad en nuestro producto y servicio, Trabajamos con pasión, Generamos competitividad buscando la excelencia, Creamos herramientas para facilitar el servicio a nuestros clientes.",
      img: ICValues,
    },
  ];

  let discountEndP = 100;
  let discountEndTags = 400;
  let discountEndRecargas = 60;

  const [discountStartPor, SetDiscountStartPor] = useState(0);
  const [discountStartTags, SetDiscountStartTags] = useState(0);
  const [discountStartRecargas, SetDiscountStartRecargas] = useState(0);
  const [boolNum, setboolNum] = useState(false);

  const porcentage = () => {
    setboolNum(true);
    if (discountStartPor < discountEndP) {
      SetDiscountStartPor(discountStartPor + 5);
    }
  };

  const tagsSell = () => {
    if (discountStartTags < discountEndTags) {
      SetDiscountStartTags(discountStartTags + 5);
    }
  };

  const tagsRec = () => {
    if (discountStartRecargas < discountEndRecargas) {
      SetDiscountStartRecargas(discountStartRecargas + 5);
    }
  };
  useEffect(() => {
    if (boolNum) {
      setTimeout(() => {
        porcentage();
      }, 30);
      setTimeout(() => {
        tagsSell();
      }, 5);
      setTimeout(() => {
        tagsRec();
      }, 145);
    }
  });
  return (
    <div className="about container mt-5" id="nosotros">
      <div className="container-about">
        <h2 className="title">Nosotros</h2>
        <p className="paragraph">
          Somos una empresa preocupada en brindar un mejor servicio a los
          usuarios que transitan por las vías con estaciones de peaje; por esto
          hemos implementado diferentes puntos de venta en sitios estratégicos
          de tal manera que se facilite la adquisición y recarga de los tags;
          además nos preocupamos de brindar el servicio con nuestros puntos
          móviles que nos permiten acercar a los sitios donde ustedes como
          usuarios nos necesitan.
        </p>
        <h3 className="subtitle">GRACIAS A NUESTRA GESTIÓN</h3>
        <div className="te-container-gestion">
          <div>
            <Fade onVisibilityChange={() => porcentage()}>
              <div className="numbersText">{discountStartPor}%</div>
              <h3>Clientes satisfechos</h3>
            </Fade>
          </div>
          <div>
            <Fade onVisibilityChange={() => tagsSell()}>
              <div className="numbersText">{discountStartTags}.000</div>
              <h3>Tags vendidos</h3>
            </Fade>
          </div>
          <div>
            <Fade onVisibilityChange={() => tagsRec()}>
              <div className="numbersText">{discountStartRecargas}.000</div>
              <h3>Recargas realizadas</h3>
            </Fade>
          </div>
        </div>
      </div>
      <div className="te-container-card">
        {dataCard.map((item) => (
          <Fade key={item.title} right>
            <Card item={item} />
          </Fade>
        ))}
      </div>
    </div>
  );
};

export const Card = ({ item, isBorder }) => {
  return (
    <div className={`te-card ${isBorder ? "border-card" : ""}`}>
      <div className="te-icon">
        <img src={item.img} alt="" />
      </div>
      <div className="te-body">
        <h3 className="subtitle">{item.title}</h3>
        <p className="paragraph">{item.content}</p>
      </div>
    </div>
  );
};
