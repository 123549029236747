import api from "../services/api";
import { privHeaders } from "../utils/utils";

async function _getHomologacionData() {
  const resp = await api.get("/api/homologacion/", { headers: privHeaders() });
  return resp.data;
}

async function _getHomologacionPendingData() {
  const resp = await api.get("/api/homologacion/pending", {
    headers: privHeaders(),
  });
  return resp.data;
}

async function _createHomologacion(data) {
  const resp = await api.post("/api/homologacion", data, {
    headers: {
      ...privHeaders(),
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
}

async function _updateHomologacion(id, data) {
  const resp = await api.patch(`/api/homologacion/update/${id}`, data, {
    headers: {
      ...privHeaders(),
    },
  });
  return resp.data;
}

async function _updateFileHomologacion(id, data) {
  const resp = await api.patch(`/api/homologacion/file/${id}`, data, {
    headers: {
      ...privHeaders(),
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
}

export {
  _getHomologacionData,
  _createHomologacion,
  _getHomologacionPendingData,
  _updateHomologacion,
  _updateFileHomologacion,
};
