import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import "./CardToll.css";

export const CardToll = ({ toll }) => {
  return (
    <Card>
      <CardHeader style={{ background: "#E8B800A1", fontWeight: "bold" }}>
        {toll.name}
      </CardHeader>
      <CardBody style={{ padding: "1rem 1.4rem" }}>
        <div>
          <div>
            <strong>Concesión:</strong>
            <span style={{ marginLeft: 4 }}>{toll.concession}</span>
          </div>
          <div>
            <strong>Dirección:</strong>
            <span style={{ marginLeft: 4 }}>{toll.location}</span>
          </div>
          <div>
            <strong>Telefono:</strong>
            <span style={{ marginLeft: 4 }}>{toll.cellPhone}</span>
          </div>
          {toll.linkMap && (
            <div
              className="container-map"
              onClick={() => window.open(toll.linkMap)}
            >
              <div className="body-map">
                <i className="fa fa-map-marker mr-2"></i>
                <span className="map-title">Ir al mapa</span>
              </div>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
