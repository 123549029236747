import { useEffect, useState } from "react";
import { _getHomologacionData } from "../../../../Controllers/homologacionCtl";
import "./index.css";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useHistory } from "react-router";
import { handleNetworkError } from "../../../../utils/utils";
import TheLoader from "../../../Misc/TheLoader";
import EditHomologacion from "./components/edit";
import { ToastContainer } from "react-toastify";

const concesionesConvert = (value) => {
  switch (value) {
    case "aloag":
      return "Aloag";
    case "consegua":
      return "Consegua";
    case "conorte":
      return "Conorte";
    case "el pan":
      return "El pan";
    default:
      return "";
  }
};

function Homologacion() {
  const history = useHistory();

  const [data, setData] = useState({
    homologacion: [],
    loading: false,
  });
  const [showEditDetalle, setShowEditDetalle] = useState(false);
  const [selectHomologacion, setSelectHomologacion] = useState(null);

  const _getHomologacion = async () => {
    try {
      const { data } = await _getHomologacionData();
      const dataTemp = data.map((item) => {
        const concesiones = item.concesiones.map((concesion) => ({
          value: concesion,
          label: concesionesConvert(concesion),
        }));
        return { ...item, concesiones };
      });

      setData((old) => ({
        ...old,
        homologacion: dataTemp,
        loading: false,
      }));
    } catch (error) {
      setData((old) => ({ ...old, loading: false }));
      handleNetworkError(error, history);
    }
  };
  useEffect(() => {
    _getHomologacion();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <ToastContainer />
      <div
        className="mb-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label className="page-title">Homologación</label>
        <div>
          <Link
            className="btonbilletera bton-yellow bton-sm menu-text"
            to="/dashboard/crear-homologacion"
          >
            Crear homologación
          </Link>
        </div>
      </div>
      {showEditDetalle ? (
        <EditHomologacion
          selectHomologacion={selectHomologacion}
          onBackTo={() => {
            setSelectHomologacion(null);
            setShowEditDetalle(false);
          }}
          updateData={(newData, id) => {
            setData((old) => ({
              ...old,
              homologacion: old.homologacion.map((item) => {
                if (item.id_homologacion === id) {
                  return { ...item, ...newData };
                }
                return item;
              }),
            }));
          }}
        />
      ) : (
        <>
          {data.loading ? (
            <TheLoader />
          ) : (
            <>
              {data.homologacion.length <= 0 && (
                <div className="text-center">
                  <h4>No registra homologaciones.</h4>
                </div>
              )}
              <Row>
                {data.homologacion.map((item) => (
                  <Col md={4} key={item.id_homologacion} className="mb-3">
                    <Card key={item.id_homologacion}>
                      <CardHeader
                        style={{
                          background: "#E8B800A1",
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label style={{ margin: 0 }}>{item.numero_tag}</label>
                        <label style={{ margin: 0 }}>{item.status}</label>
                      </CardHeader>
                      <CardBody style={{ padding: "1rem 1.4rem" }}>
                        <Row>
                          <Col md={6}>
                            <div>
                              <strong>Nombres:</strong>
                              <span style={{ marginLeft: 4 }}>
                                {item.nombres} {item.apellidos}
                              </span>
                            </div>
                            <div>
                              <strong>Correo:</strong>
                              <span style={{ marginLeft: 4 }}>
                                {item.correo}
                              </span>
                            </div>
                            <div>
                              <strong>Tipo de identificacion:</strong>
                              <span style={{ marginLeft: 4 }}>
                                {item.tipo_identificacion}
                              </span>
                            </div>
                            <div>
                              <strong>Razon social:</strong>
                              <span style={{ marginLeft: 4 }}>
                                {item.razon_social}
                              </span>
                            </div>
                            <div>
                              <strong>Identificacion:</strong>
                              <span style={{ marginLeft: 4 }}>
                                {item.identificacion}
                              </span>
                            </div>
                            <div>
                              <strong>Dirección:</strong>
                              <span style={{ marginLeft: 4 }}>
                                {item.direccion}
                              </span>
                            </div>
                            <div>
                              <strong>Telefono:</strong>
                              <span style={{ marginLeft: 4 }}>
                                {item.telefono}
                              </span>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <strong>Marca:</strong>
                              <span style={{ marginLeft: 4 }}>
                                {item.marca}
                              </span>
                            </div>
                            <div>
                              <strong>Modelo:</strong>
                              <span style={{ marginLeft: 4 }}>
                                {item.modelo}
                              </span>
                            </div>
                            <div>
                              <strong>Placa:</strong>
                              <span style={{ marginLeft: 4 }}>
                                {item.placa}
                              </span>
                            </div>
                            <div>
                              <strong>Categoria:</strong>
                              <span style={{ marginLeft: 4 }}>
                                {item.categoria}
                              </span>
                            </div>
                            <div>
                              <strong>Ejes:</strong>
                              <span style={{ marginLeft: 4 }}>{item.ejes}</span>
                            </div>
                            <div>
                              <strong>Color:</strong>
                              <span style={{ marginLeft: 4 }}>
                                {item.color}
                              </span>
                            </div>
                          </Col>
                          <Col md={12}>
                            {item.observacion && (
                              <>
                                <strong>Observacion:</strong>
                                <div>
                                  <span>{item.observacion}</span>
                                </div>
                              </>
                            )}
                          </Col>
                          <Col md={12}>
                            <div
                              className="container-map"
                              onClick={() => {
                                setShowEditDetalle(true);
                                setSelectHomologacion(item);
                              }}
                            >
                              <div className="body-map">
                                <span className="map-title">
                                  Ver detalle completo
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
}
export default Homologacion;
