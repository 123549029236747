import React, { createContext, useContext, useState } from 'react';

// The context itself
const UserContext = createContext();

// Custom hook to ease imports inside components
export function useUserContext() {
  return useContext(UserContext);
}

// JSX context provider
export function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLogged, setIsLogged] = useState(false);

  function updateUser(newUser) {
    setUser(newUser);
  }

  function updateIsLogged(newIsLogged) {
    setIsLogged(newIsLogged);
  }



  // This object is the one that will be globally disponible 
  let contextData = {
    user,
    isLogged,
    updateUser,
    updateIsLogged,
  }

  return (
    <UserContext.Provider value={contextData}>
      {children}
    </UserContext.Provider>
  )
}