import React, { useCallback, useEffect, useState } from "react";
import RegisterForm from "../RegisterForm";
import api from "../../../../services/api";
import {
  pubHeaders,
  privHeaders,
  handleNetworkError,
} from "../../../../utils/utils";
import { useUserContext } from "../../../../Context/UserContext";
import { ToastContainer } from "react-toastify";
import { notifyWarning } from "../../../Misc/InfoItems/toasts";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import { Row, Col, Form, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { ReactComponent as LoginImg } from "../../../../Assets/login-img-2.svg";
import logoTagExpress from "../../../../Assets/logo-sin-fondo-tag-express.png";
import IconInfo from "../../../../Assets/info.png";
import eye from "../../../../Assets/show_password.png";
import hideEye from "../../../../Assets/hidden_password.png";
import WhatssapButton from "../../../Misc/WhatssapButton";

const Login = ({ history }) => {
  const context = useUserContext();
  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const verifyToken = useCallback(async () => {
    const t = localStorage.getItem("t");

    if (t == null) {
      return;
    }

    if (context.isLogged === true) {
      history.replace("/dashboard/informacion");
      return;
    }

    try {
      const response = await api.post(
        "/api/auth",
        {},
        {
          headers: privHeaders(),
        }
      );

      if (response.data.status === "autorizado_ok") {
        //context for user
        context.updateIsLogged(true);
        context.updateUser(response.data.data.u);
        history.replace("/dashboard/informacion");
      }
    } catch (error) {}
  }, [context, history]);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    const data = {
      usuario_input: user,
      password_input: password,
    };

    try {
      const response = await api.post("/api/auth/login", data, {
        headers: pubHeaders(),
      });

      if (response.data.status === "autorizado_ok") {
        //context for user
        context.updateIsLogged(true);
        context.updateUser(response.data.data.u);
        localStorage.setItem("t", response.data.data.t);
        history.replace("/dashboard/informacion");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data &&
        error.response.data.status === "CUENTA_NO_VERIFICADA"
      ) {
        notifyWarning(
          "Su cuenta no ha sido verificada todavía. Se acaba de enviar un correo de verificación a " +
            error.response.data.correo +
            ". En caso de no hallarlo, revise la sección de spam del correo",
          { autoClose: 15000 }
        );
        return;
      }

      if (error.response && error.response.data.status === "CUENTA_BLOQUEADA") {
        notifyWarning(
          "Su cuenta está bloqueada por superar los intento permitidos. Por favor comuníquese al correo de atención al cliente que se encuentra en la página principal",
          { autoClose: 15000 }
        );
        return;
      }
      notifyWarning("Usuario o contraseña incorrecta");
      if (
        error.response &&
        error.response.data.status === "CUENTA_CASI_BLOQUEADA"
      ) {
        notifyWarning(
          "Tiene permitido un intento más. Si falla de nuevo, su cuenta será bloqueada",
          { autoClose: 8000 }
        );
        return;
      }
      handleNetworkError(history, error);
    }
  };

  // show or hide register from Modal
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  //show or hide password text
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // chnage inverse the boolean state passwordShown
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <ToastContainer />
      <div className="login-wrapper">
        <RegisterForm toggle={toggle} modal={modal} setModal={setModal} />
        <Row md="12">
          <Col md="6">
            <Link to="/">
              <div className="login-logo-tag">
                <img src={logoTagExpress} alt="logo tag express" />
              </div>
            </Link>
            <div className="image-login">
              <LoginImg />
            </div>
            <div className="login-card-services">
              <div className="login-card" style={{ opacity: 0.9 }}>
                <div className="login-title">
                  <h6 style={{ fontSize: "14px" }}>
                    <strong>
                      ACCEDE A TODOS NUESTROS SERVICIOS SIN SALIR DE CASA
                    </strong>
                  </h6>
                </div>
                <div className="grid-tips">
                  <div className="tips">
                    <span className="tip-circle-login">
                      <i className="fa fa-search"></i>
                    </span>
                    <p className="ty-text-description">
                      <strong>
                        Consulta saldos de las Concesionarias: Alóag, Concegua,
                        Conorte y El Pan
                      </strong>
                    </p>
                  </div>
                  <div className="tips">
                    <span className="tip-circle-login">
                      <i className="fa fa-car"></i>
                    </span>
                    <p className="ty-text-description">
                      <strong>
                        Consulta pasadas de las Concesionarias: Alóag, Concegua,
                        Conorte y El Pan
                      </strong>
                    </p>
                  </div>
                  <div className="tips">
                    <span className="tip-circle-login">
                      <i className="fa fa-map"></i>
                    </span>
                    <p className="ty-text-description">
                      <strong>
                        Consulta la ubicación y télefonos de los peajes que se
                        encuentran en las concesiones de: Alóag, Concegua,
                        Conorte y El Pan
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="6" className="login-side">
            <div className="login-card">
              <div className="login-title">
                <h4>
                  <b>ACCESO</b>
                </h4>
              </div>
              <div>
                <Form onSubmit={handleSubmitLogin} className="mt-3">
                  <FormGroup>
                    <p className="text-left">
                      <strong>Correo</strong>
                    </p>
                    <Input
                      type="text"
                      name="usuario"
                      id="usuario"
                      onChange={(e) => setUser(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <div
                      className="text-left"
                      style={{ marginBottom: "1rem", display: "flex", gap: 5 }}
                    >
                      <strong>Contraseña</strong>{" "}
                      <span className="tooltipCustom bottom">
                        <img src={IconInfo} alt="Icon Info" />
                        <div className="tooltiptext">
                          <p>
                            En caso de que supere los 5 intentos de iniciar
                            sesión su cuenta será bloqueada.
                          </p>
                          <p>
                            Le recomendamos que si no se acuerda de su clave,{" "}
                            <Link to="/usuarios/password">haga click aquí</Link>{" "}
                            para ir al formulario de recuperar clave.
                          </p>
                        </div>
                      </span>
                    </div>
                    <Input
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      id="examplePassword"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span onClick={togglePassword} className="show-password">
                      <img
                        style={{ height: "20px" }}
                        src={passwordShown ? eye : hideEye}
                        alt="show password"
                      />
                    </span>
                    <div className="mt-1 text-right">
                      <Link
                        className="link forgot-password"
                        to="/usuarios/password"
                      >
                        ¿Olvidaste tu contraseña?
                      </Link>
                    </div>
                  </FormGroup>
                  <button
                    style={{ cursor: "pointer" }}
                    className="bton bton-yellow bton-sm login-btn mt-3"
                  >
                    Ingresar
                  </button>
                </Form>
              </div>
              <hr></hr>
              <Link className="link" onClick={toggle} to="#">
                <div className="login-footer">
                  <Row className="pt-1">
                    <Col>¿Usuario nuevo? Registrate aquí</Col>
                  </Row>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
      <WhatssapButton />
    </>
  );
};

export default Login;
