import React, { useState } from "react";
import { Bounce } from "react-awesome-reveal";
import { Accordion } from "../../../../Misc/Accordion/Accordion";

import "./faq.css";
import TableRate from "../../../Rate/components/TableRate/TableRate";

const dataFaq = [
  {
    search: "donde puedo comprar el tag",
    title: "¿Dónde puedo comprar el tag?",
    content: (
      <>
        <p>Puedes adquirir el tag en los siguientes puntos de venta físicos:</p>
        <ul>
          <li>Autopista General Rumiñahui(Quito)</li>
          <li>Pharmacys(Guayaquil, Quito y Vía Samborondón)</li>
          <li>
            Farmacias Cruz Azul(Milagro, El Triunfo, Naranjal, Yaguachi y Daule)
          </li>
          <li>
            Oficinas de atención al cliente de los peajes de CONORTE, CONCEGUA y
            PAN(Guayas)
          </li>
          <li>Oficina de atención al cliente del peaje de Aloág.</li>
        </ul>
        <p>Puedes adquirir el tag en los siguientes puntos de venta online:</p>
        <ul>
          <li>Tienda virtual de Almacenes Tía</li>
          <li>
            App Rappi(Vía Samborondón y Peaje Autopista General Rumiñahui)
          </li>
        </ul>
      </>
    ),
  },
  {
    search: "en que peajes funciona el tag",
    title: "¿En qué peajes funciona el tag?",
    content: (
      <p>
        El tag funciona en todos los peajes de las concesionarias de Conorte,
        Concegua, Alóag y el Pan. Para visualizar cuáles son, dale click aquí.
      </p>
    ),
  },
  // {
  //   search: "que es la e-wallet y para que sirve",
  //   title: "¿Qué es la e-wallet y para qué sirve?",
  //   content: (
  //     <p>
  //       La e-wallet es una billetera electrónica que permite con un sólo saldo
  //       pasar por todos los peajes de las concesiones mencionadas.
  //     </p>
  //   ),
  // },
  {
    search: "que debo hacer despues de comprar el tag",
    title: "¿Qué debo hacer después de comprar el tag?",
    content: <p>Debe crear una cuenta de Tag Express y hacer una recarga.</p>,
  },
  {
    search: "como crear mi cuenta de tag express",
    title: "¿Cómo crear mi cuenta de Tag Express?",
    content: (
      <>
        <p>
          Su cuenta de Tag Express puede ser creada desde la página web o desde
          la aplicación móvil de la siguiente forma:
        </p>
        <h6>PÁGINA WEB</h6>
        <ul>
          <li>
            En la parte superior derecha de la página web, dar click al botón
            que dice “Acceso”.
          </li>
          <li>Darle click a la opción “¿Usuario nuevo? Regístrate aquí”.</li>
          <li>
            Seleccionar si es Empresa o no y llenar todos los datos que pide.
          </li>
          <li>Darle click en “Registrar”</li>
        </ul>
        <h6>APLICACIÓN MÓVIL</h6>
        <ul>
          <li>Descargar la app</li>
        </ul>
        <p>
          <strong>Importante:</strong> si los datos registrados no coinciden con
          los de su vehículo su tag será inhabilitado y tendrá que adquirir
          otro.
        </p>
      </>
    ),
  },
  {
    search: "como puedo recargar el tag",
    title: "¿Cómo puedo recargar el tag?",
    content: (
      <>
        <p>
          Las recargas se pueden realizar presencialmente a nivel nacional en
          todas las agencias de la Red Activa de Western Union:
        </p>
        <ul>
          <li>Oficinas Tagexpress</li>
          <li>Oficinas Western Union a nivel nacionak</li>
          <li>
            Todas las oficinas de atención al cliente de los peajes de CONORTE,
            CONCEGUA, PAN y ALÓAG.
          </li>
        </ul>
        <p>
          <strong>Nota:</strong> Las recargas se realizan de inmediato y tienen
          un monto mínimo de $5. No hay un monto máximo.
        </p>
      </>
    ),
  },
  {
    search: "puedo hacer recargas mediante transferencia o cheque",
    title: "¿Puedo hacer recargas mediante transferencia o cheque?",
    content: (
      <>
        <p>¡Claro que sí!</p>
        <p>
          Para mayor información sobre los detalles de esas formas de pago
          contactarse con las oficinas de atención al cliente de los peajes
          mencionados anteriormente.
        </p>
      </>
    ),
  },
  {
    search: "como pegar el tag en mi vehiculo",
    title: "¿Cómo pegar el tag en mi vehículo?",
    content: (
      <p>
        Para ver cómo debe pegar su tag paso a paso, de{" "}
        <a
          href="https://www.youtube.com/watch?v=Z6t3dKRSyhs"
          target="_blank"
          rel="noreferrer"
        >
          click aquí.
        </a>
      </p>
    ),
  },
  {
    search: "cual es el costo de cada peaje",
    title: "¿Cuál es el costo de cada peaje?",
    content: <TableRate />,
  },
];

export const Faq = () => {
  const [search, setSearch] = useState("");

  const [listQuestions, setListQuestions] = useState(dataFaq);
  return (
    <div className="container faq" id="preguntas">
      <input
        name="search"
        value={search}
        placeholder="Buscar preguntas"
        className="mb-3 search"
        onChange={(e) => {
          const value = e.target.value;
          setSearch(value);
          if (value.length > 5) {
            const newList = listQuestions.filter((list) => {
              return list.search.toLowerCase().indexOf(value) > -1;
            });
            setListQuestions(newList);
          } else {
            setListQuestions(dataFaq);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            const newList = listQuestions.filter((list) => {
              return list.search.toLowerCase().indexOf(event.target.value) > -1;
            });
            setListQuestions(newList);
          }
        }}
      />
      <Bounce style={{ width: "100%" }}>
        <Accordion>
          {listQuestions.map((item) => (
            <Accordion.Item key={item.title}>
              <Accordion.Header>{item.title}</Accordion.Header>
              <Accordion.Body>{item.content}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Bounce>
    </div>
  );
};
