import { Fragment, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {
  _createHomologacion,
  _getHomologacionPendingData,
} from "../../../../Controllers/homologacionCtl";
import { handleNetworkError } from "../../../../utils/utils";
import { notifyWarning } from "../../../Misc/InfoItems/toasts";
import AlertMessage from "../../../Misc/AlertMessage";
import Html5QrcodePlugin from "./components/Html5QrcodePlugin";
import Select from "react-select";

import "./index.css";

function CrearHomologacion() {
  const history = useHistory();

  const [state, setState] = useState({
    reload: false,
    loading: false,
  });
  const [data, setData] = useState({
    pending: true,
    loading: false,
  });
  const [chkEmpresa, setChkEmpresa] = useState(false);
  const selectRef = useRef(false);

  const _getHomologacionPending = async () => {
    try {
      const { pending } = await _getHomologacionPendingData();
      setData((old) => ({
        ...old,
        pending,
        loading: false,
      }));
    } catch (error) {
      setData((old) => ({ ...old, loading: false }));
      handleNetworkError(error, history);
    }
  };
  useEffect(() => {
    _getHomologacionPending();
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const _onSubmit = async (data) => {
    setState((old) => ({ ...old, loading: true }));
    const dataTemp = {
      ...data,
      concesiones: data.concesiones.map((item) => item.value),
    };

    const formData = new FormData();
    Object.keys(dataTemp).forEach((key) => {
      formData.append(key, dataTemp[key]);
    });
    formData.append("es_empresa", chkEmpresa);

    try {
      await _createHomologacion(formData);
      setState((old) => ({ ...old, loading: false }));
      history.push("/dashboard/homologacion");
    } catch (error) {
      setState((old) => ({ ...old, loading: false }));
      handleNetworkError(error, history);
      if (error.response.data.message) {
        notifyWarning(error.response.data.message);
        return;
      }
    }
  };

  const checkCompany = (e) => {
    if (e.target.checked) {
      setChkEmpresa(!chkEmpresa);
      selectRef.current = true;
    } else {
      setChkEmpresa(!chkEmpresa);
      selectRef.current = false;
    }
  };

  const datosPersonales = [
    {
      name: "nombres",
      placeholder: "Nombres",
      requerid: "Los nombres es requerido",
      isSelect: false,
      valueDefault: "",
    },
    {
      name: "apellidos",
      placeholder: "Apellidos",
      requerid: "Los apellidos es requerido",
      isSelect: false,
      valueDefault: "",
    },
    {
      name: "razon_social",
      placeholder: "Razon social",
      requerid: "La razon social es requerido",
      isSelect: false,
      valueDefault: "",
    },
    {
      name: "tipo_identificacion",
      placeholder: "Tipo identificación",
      requerid: "El tipo identificación es requerido",
      isSelect: true,
      valueDefault: "CEDULA",
      options: [
        {
          value: "",
          label: "Seleccione",
        },
        {
          value: "RUC",
          label: "RUC",
        },
        {
          value: "CEDULA",
          label: "CÉDULA",
        },
      ],
    },
    {
      name: "identificacion",
      placeholder: "identificación",
      requerid: "La identificación es requerido",
      isSelect: false,
      valueDefault: "",
    },
    {
      name: "correo",
      placeholder: "Correo",
      requerid: "El correo es requerido",
      isSelect: false,
      valueDefault: "",
    },
    {
      name: "direccion",
      placeholder: "Dirección",
      requerid: "La dirección es requerido",
      isSelect: false,
      valueDefault: "",
    },
    {
      name: "telefono",
      placeholder: "Teléfono",
      requerid: "El teléfono es requerido",
      isSelect: false,
      valueDefault: "",
    },
  ];

  const datosVehiculo = [
    {
      name: "marca",
      placeholder: "Marca",
      requerid: "La marca es requerido",
      isSelect: false,
      valueDefault: "",
    },
    {
      name: "modelo",
      placeholder: "Modelo",
      requerid: "El modelo es requerido",
      isSelect: false,
      valueDefault: "",
    },
    {
      name: "placa",
      placeholder: "Placa",
      requerid: "La placa es requerido",
      isSelect: false,
      valueDefault: "",
    },
    {
      name: "categoria",
      placeholder: "Categoría",
      requerid: "La categoría es requerido",
      isSelect: true,
      valueDefault: "",
      options: [
        {
          value: "",
          label: "Seleccione",
        },
        {
          value: "CATEGORIA 1",
          label: "CATEGORIA 1",
        },
        {
          value: "CATEGORIA 2",
          label: "CATEGORIA 2",
        },
        {
          value: "CATEGORIA 3",
          label: "CATEGORIA 3",
        },
        {
          value: "CATEGORIA 4",
          label: "CATEGORIA 4",
        },
        {
          value: "CATEGORIA 5",
          label: "CATEGORIA 5",
        },
        {
          value: "CATEGORIA 6",
          label: "CATEGORIA 6",
        },
      ],
    },
    {
      name: "color",
      placeholder: "Color",
      requerid: "El color es requerido",
      isSelect: false,
      valueDefault: "",
    },
    {
      name: "ejes",
      placeholder: "Ejes",
      requerid: "El ejes es requerido",
      isSelect: false,
      valueDefault: "",
    },
  ];

  const [showScanner, setShowScanner] = useState(false);
  const [tagError, setTagError] = useState(false);
  const [decodedResult, setDecodedResult] = useState([]);
  const onNewScanResult = (decodedText, decodedResult) => {
    if (decodedText) {
      if (decodedText.length === 24) {
        setDecodedResult(decodedText);
        setShowScanner(false);
        setValue("numero_tag", decodedText);
        setTagError(false);
      } else {
        setTagError(true);
        setShowScanner(false);
      }
    }
  };

  const options = [
    { value: "aloag", label: "Alóag" },
    { value: "consegua", label: "Consegua" },
    { value: "conorte", label: "Conorte" },
    { value: "el pan", label: "El pan" },
  ];

  return (
    <div>
      <div
        className="mb-4"
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <div>
          <Link
            className="btonbilletera bton-yellow bton-sm menu-text"
            to="/dashboard/homologacion"
          >
            Regresar
          </Link>
        </div>
        <label className="page-title" style={{ margin: 0 }}>
          Crear una homologación
        </label>
      </div>
      {showScanner && (
        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />
      )}
      {data.pending ? (
        <AlertMessage
          text={
            "Estimado usuario usted tiene una homologación pendiente, en proceso o por editar. Por favor hasta que finalice la homologación anterior no se podrá crear una nueva. \n Para más información comuníquese con los administradores."
          }
        />
      ) : (
        <div className="simple-card-profile mt-4">
          <Form onSubmit={handleSubmit(_onSubmit)}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Tag
                    <span className="text-danger"> *</span>
                  </Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Tag"
                    {...register("numero_tag", {
                      required: {
                        value: true,
                        message: "El tag es requerido",
                      },
                    })}
                    defaultValue={decodedResult}
                  />
                  <small className="text-danger">
                    {errors.numero_tag && errors.numero_tag.message}
                  </small>
                </FormGroup>
                <button
                  className="bton-link bton-white mb-3"
                  onClick={() => setShowScanner(!showScanner)}
                  type="button"
                >
                  {!showScanner ? "Escanear código" : "Cerrar escáner"}
                </button>
                {tagError && (
                  <small className="text-danger"> Tag no valido </small>
                )}
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Concesiones
                    <span className="text-danger"> *</span>
                  </Label>
                  <Controller
                    name="concesiones"
                    control={control}
                    rules={{ required: "Concesiones es requerida" }}
                    render={({ field }) => (
                      <Select isMulti {...field} options={options} />
                    )}
                  />
                  <small className="text-danger">
                    {errors.concesiones && errors.concesiones.message}
                  </small>
                </FormGroup>
              </Col>
            </Row>
            <div className="custom-control custom-switch mb-2">
              <input
                type="checkbox"
                className="custom-control-input"
                {...register("checkCompany", { onchange: checkCompany })}
                id="checkCompany"
              />
              <label htmlFor="checkCompany" className="custom-control-label">
                <strong>Empresa</strong>
              </label>
            </div>
            <Row>
              <Col md="3">
                <h2>Datos personales</h2>
                {datosPersonales.map((item, index) => (
                  <Fragment key={index}>
                    {item.isSelect ? (
                      <FormGroup>
                        <Label>
                          {item.placeholder}
                          <span className="text-danger"> *</span>
                        </Label>
                        <select
                          className="form-control"
                          id={item.name}
                          {...register(item.name, {
                            required: true,
                          })}
                          defaultValue={item.valueDefault}
                        >
                          {item.options.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <small className="text-danger">
                          {errors[item.name] && errors[item.name].message}
                        </small>
                      </FormGroup>
                    ) : (
                      <FormGroup>
                        <Label>
                          {item.placeholder}
                          <span className="text-danger"> *</span>
                        </Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={item.placeholder}
                          {...register(item.name, {
                            required: {
                              value: true,
                              message: item.requerid,
                            },
                          })}
                          defaultValue={item.valueDefault}
                        />
                        <small className="text-danger">
                          {errors[item.name] && errors[item.name].message}
                        </small>
                      </FormGroup>
                    )}
                  </Fragment>
                ))}
              </Col>
              <Col md="3">
                <h2>Datos del vehículo</h2>
                {datosVehiculo.map((item, index) => (
                  <Fragment key={index}>
                    {item.isSelect ? (
                      <FormGroup>
                        <Label>
                          {item.placeholder}
                          <span className="text-danger"> *</span>
                        </Label>
                        <select
                          className="form-control"
                          id={item.name}
                          {...register(item.name, {
                            required: true,
                          })}
                          defaultValue={item.valueDefault}
                        >
                          {item.options.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <small className="text-danger">
                          {errors[item.name] && errors[item.name].message}
                        </small>
                      </FormGroup>
                    ) : (
                      <FormGroup>
                        <Label>
                          {item.placeholder}
                          <span className="text-danger"> *</span>
                        </Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={item.placeholder}
                          {...register(item.name, {
                            required: {
                              value: true,
                              message: item.requerid,
                            },
                          })}
                          defaultValue={item.valueDefault}
                        />
                        <small className="text-danger">
                          {errors[item.name] && errors[item.name].message}
                        </small>
                      </FormGroup>
                    )}
                  </Fragment>
                ))}
              </Col>
              <Col md="6">
                <h2>Imagenes</h2>
                <FormGroup>
                  <Label>
                    Cédula Anverso
                    <span className="text-danger"> *</span>
                  </Label>
                  <Controller
                    control={control}
                    name={"matricula_anverso"}
                    rules={{ required: "Cédula Anverso es requerida" }}
                    render={({ field: { value, onChange, ...field } }) => {
                      return (
                        <Input
                          {...field}
                          value={value?.fileName}
                          onChange={(event) => {
                            onChange(event.target.files[0]);
                          }}
                          type="file"
                          id="matricula_anverso"
                          accept=".png, .jpg, .jpeg"
                        />
                      );
                    }}
                  />
                  <small className="text-danger">
                    {errors.matricula_anverso &&
                      errors.matricula_anverso.message}
                  </small>
                </FormGroup>
                <FormGroup>
                  <Label>
                    Cédula Reverso
                    <span className="text-danger"> *</span>
                  </Label>
                  <Controller
                    control={control}
                    name={"matricula_reverso"}
                    rules={{ required: "Cédula Reverso es requerida" }}
                    render={({ field: { value, onChange, ...field } }) => {
                      return (
                        <Input
                          {...field}
                          value={value?.fileName}
                          onChange={(event) => {
                            onChange(event.target.files[0]);
                          }}
                          type="file"
                          id="matricula_reverso"
                          accept=".png, .jpg, .jpeg"
                        />
                      );
                    }}
                  />
                  <small className="text-danger">
                    {errors.matricula_reverso &&
                      errors.matricula_reverso.message}
                  </small>
                </FormGroup>
                <FormGroup>
                  <Label>
                    Matrícula Anverso
                    <span className="text-danger"> *</span>
                  </Label>
                  <Controller
                    control={control}
                    name={"cedula_anverso"}
                    rules={{ required: "Matrícula Anverso es requerida" }}
                    render={({ field: { value, onChange, ...field } }) => {
                      return (
                        <Input
                          {...field}
                          value={value?.fileName}
                          onChange={(event) => {
                            onChange(event.target.files[0]);
                          }}
                          type="file"
                          id="cedula_anverso"
                          accept=".png, .jpg, .jpeg"
                        />
                      );
                    }}
                  />
                  <small className="text-danger">
                    {errors.cedula_anverso && errors.cedula_anverso.message}
                  </small>
                </FormGroup>
                <FormGroup>
                  <Label>
                    Matrícula Reverso
                    <span className="text-danger"> *</span>
                  </Label>
                  <Controller
                    control={control}
                    name={"cedula_reverso"}
                    rules={{ required: "Matrícula Reverso es requerido" }}
                    render={({ field: { value, onChange, ...field } }) => {
                      return (
                        <Input
                          {...field}
                          value={value?.fileName}
                          onChange={(event) => {
                            onChange(event.target.files[0]);
                          }}
                          type="file"
                          id="cedula_reverso"
                          accept=".png, .jpg, .jpeg"
                        />
                      );
                    }}
                  />
                  <small className="text-danger">
                    {errors.cedula_reverso && errors.cedula_reverso.message}
                  </small>
                </FormGroup>
                <FormGroup>
                  <Label>
                    Placa (Foto frontal del vehículo)
                    <span className="text-danger"> *</span>
                  </Label>
                  <Controller
                    control={control}
                    name={"vehiculo"}
                    rules={{ required: "Placa es requerida" }}
                    render={({ field: { value, onChange, ...field } }) => {
                      return (
                        <Input
                          {...field}
                          value={value?.fileName}
                          onChange={(event) => {
                            onChange(event.target.files[0]);
                          }}
                          type="file"
                          id="vehiculo"
                          accept=".png, .jpg, .jpeg"
                        />
                      );
                    }}
                  />
                  <small className="text-danger">
                    {errors.vehiculo && errors.vehiculo.message}
                  </small>
                </FormGroup>
              </Col>
            </Row>
            {state.loading ? (
              <></>
            ) : (
              <button
                style={{ cursor: "pointer" }}
                className="bton bton-yellow bton-sm mt-2"
                id="registrar"
              >
                Crear homologacion
              </button>
            )}
          </Form>
        </div>
      )}
    </div>
  );
}
export default CrearHomologacion;
