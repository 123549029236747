import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./carousel.css";
import generalInfo from "../../../../../Assets/roulette-img-1-interactive.gif";
import importantInfo from "../../../../../Assets/roulette-img-2-interactive.gif";
import tagExpressLogo from "../../../../../Assets/logo-con-frase.png";
import roadInfo from "../../../../../Assets/roadInfo.gif";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

// const items = [
//   {
//     src: generalInfo,
//     altText: "Tag Express can be used in all tolls",
//     header: tagExpressLogo,
//     caption:
//       "¡Recorre los peajes del Ecuador como nunca antes con TAG EXPRESS! Gracias a nuestra billetera electrónica podrás circular por los peajes asociados con un sólo saldo",
//   },
//   {
//     src: wallet,
//     altText: "Tag Express wallet",
//     header: tagExpressLogo,
//     caption:
//       "A través de nuestra billetera podrás pagar peajes, hacer recargas, consultar saldo, pasadas y transacciones.",
//     ctaBtn: <a href="#wallet">Descargala aquí</a>,
//   },
//   {
//     src: importantInfo,
//     altText: "Important information",
//     header: tagExpressLogo,
//     caption:
//       "Consulta de saldos y pasadas De las Concesionarias: Alóag, Concegua, Conorte y El Pan.",
//     ctaBtn: (
//       <Link to={"/login"} target="_blank">
//         Consulta aquí
//       </Link>
//     ),
//   },
//   {
//     src: parking,
//     altText: "Tag Express in parking",
//     header: tagExpressLogo,
//     caption:
//       "Use la billetera y su tag para pagar su estancia en parqueos asociados. Con el saldo de su tag podrá pagar mas que peajes",
//   },
// ];

const items = [
  {
    src: generalInfo,
    altText: "Tag Express can be used in all tolls",
    header: tagExpressLogo,
    caption:
      "¡Recorre los peajes del Ecuador como nunca antes con TAG EXPRESS! Gracias a nuestro tag y servicios electrónicos viajarás con mayor seguridad y comodidad",
  },
  {
    src: roadInfo,
    altText: "Tag Express road info",
    header: tagExpressLogo,
    caption:
      "consulte los estados de las vías en tiempo real. Viaje informado y elija las rutas más eficientes. ¡Su viaje será más rápido y sin sorpresas!",
  },
  {
    src: importantInfo,
    altText: "Important information",
    header: tagExpressLogo,
    caption:
      "Consulta de saldos y pasadas De las Concesionarias: Alóag, Concegua, Conorte y El Pan.",
    ctaBtn: (
      <Link to={"/login"} target="_blank">
        Consulta aquí
      </Link>
    ),
  },
];

export const TagExpressCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  let animating;

  const onExiting = () => {
    animating = true;
  };

  const onExited = () => {
    animating = false;
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={item.src}>
        <div className="carouselCont">
          <div className="TextCont">
            <CarouselCaption
              captionHeader={<img width="80%" src={item.header} alt="" />}
              captionText={item.caption.toLocaleUpperCase()}
            />
          </div>
          <div className="ImgCont">
            <img width="70%" src={item.src} alt={item.altText} />
            {item.ctaBtn ? <div className="cta-btn">{item.ctaBtn}</div> : null}
          </div>
        </div>
      </CarouselItem>
    );
  });

  return (
    <div>
      <Carousel
        className="trustedMechCarousel"
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </div>
  );
};
