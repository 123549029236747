import moment from 'moment';

// Returns date in format: yyyy-mm-dd <HH:mm:ss>
const getDate = (withTime) => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  let hours = String(today.getHours()).padStart(2, "0");
  let minutes = String(today.getMinutes()).padStart(2, "0");
  let seconds = String(today.getSeconds()).padStart(2, "0");

  today = yyyy + "-" + mm + "-" + dd;

  if (withTime) {
    today += " " + hours + ":" + minutes + ":" + seconds;
  }

  return today;
};

// Converts a date to a human friendly format: Viernes, 3 de febrero del 2021
const toHumanStyleDate = (originalDate) => {
  const weekdays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  // First item is null to ease mapping of months which are 1-indexed
  const months = [
    null, 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
    'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  const date = moment.utc(originalDate);
  const weekDay = date.format('d');
  const monthDay = date.format('D');
  const month = date.format('M');
  const year = date.format('YYYY');
  return weekdays[weekDay] + ', ' + monthDay + ' de ' + months[month] + ' del ' + year;
}

// Receives a UTC date and returns that date in local format
const toLocalDate = (utcDate) => {
  if (isNaN(Date.parse(utcDate))) {
    throw new Error('Invalid Date');
  }

  const date = new Date(utcDate);

  return date.getFullYear().toString().padStart(2, '0') + "-" +
    (date.getMonth() + 1).toString().padStart(2, '0') + "-" +
    date.getDate().toString().padStart(2, '0') + " "
    + date.getHours().toString().padStart(2, '0') + ":"
    + date.getMinutes().toString().padStart(2, '0') + ':'
    + date.getSeconds().toString().padStart(2, '0');
}

function strIsValidDate(date) { // expects yyyy-mm-dd
  const parsedDate = new Date(date);
  if (Object.prototype.toString.call(parsedDate) === "[object Date]") {
    if (isNaN(parsedDate.getTime())) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

// calculate number of days between two dates
const calculateDays = (initDate, finalDate) => {
  let finalDays = moment.duration(moment(finalDate).diff(moment(initDate))).asDays()
  return Number(finalDays);
}


export {
  toHumanStyleDate,
  getDate,
  toLocalDate,
  strIsValidDate,
  calculateDays
}
