import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { CardToll } from "./components/CardToll";
import TollInformationData from "../../../../utils/json/concession.json";

export const TollInformation = () => {
  const [dataRaw] = useState(TollInformationData);

  const [data, setData] = useState(TollInformationData);
  const [filterData, setFilterData] = useState({
    concession: "",
    toll: "",
  });

  useEffect(() => {
    if (filterData.concession) {
      const filter = dataRaw.filter(
        (item) => item.concessionValue === filterData.concession
      );
      setData(filter);
      return;
    }
    setData(dataRaw);
  }, [filterData, dataRaw]);

  const change = (event) => {
    event.persist();
    setFilterData((old) => ({ ...old, concession: event.target.value }));
  };

  return (
    <div>
      <label className="page-top-title">Información de peajes</label>
      <Row>
        <Col md={12}>
          <Col md={3}>
            <div className="form-group mb-3">
              <label>Concesión:</label>
              <select
                className="form-control"
                onChange={change}
                defaultValue={filterData.concession}
              >
                <option value="">Seleccione un concesión</option>
                <option value="conorte">Conorte</option>
                <option value="concegua">Concegua</option>
                <option value="el_pan">El Pan</option>
                <option value="aloag">Aloag</option>
              </select>
            </div>
          </Col>
        </Col>
        {data.map((item, index) => (
          <Col md={4} key={index} className="mb-3">
            <CardToll toll={item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};
