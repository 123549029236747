import React from "react";
import { Row, Col } from "reactstrap";
import "./informacion.css";
import WhatssapButton from "../../../Misc/WhatssapButton";
import CheckBalance from "../../../../Assets/svg/check_balance.svg";
import CheckPast from "../../../../Assets/svg/check_transits.svg";
import Attention from "../../../../Assets/svg/attention.svg";
import Wallet from "../../../../Assets/svg/e-wallet.svg";
// import Apple from "../../../../Assets/app-store.png";
// import PlayStore from "../../../../Assets/google-play.png";

const Informacion = () => {
  return (
    <div>
      <label className="page-top-title">Información importante</label>
      <Row className="mb-4 mt-4">
        <Col md="3">
          <div className="info-card">
            <div className="card-img">
              <img src={Wallet} alt="" />
            </div>
            {/* <div className="card-info-body">
              <div className="info-card__title">Wallet Tag Express</div>
              <div className="info-card__subtitle">
                ¿Que es Wallet?
              </div>
              <div className="info-card__content">
                Es nuestra aplicación móvil que te permite realizar pagos automáticos, revisar saldo, transacciones y
                solicitar asistencia de grúas y ambulancias en las vías.<br></br>
                <strong>
                  Con tu billetera podrás tener un solo saldo y usarlos en todas las estaciones de peajes asociadas. Se termino el dolor de cabeza de tener varias cuentas y diferentes saldos.Con tu billetera podrás realizar transacciones de manera mas ágil y rápida.
                </strong>
                <div className="phone-stores">
                  <a href="https://www.apple.com/app-store/" rel="noreferrer" target="_blank"><img className="stores-img" src={Apple} alt="App store" /></a>
                  <a href="https://play.google.com/store/games" rel="noreferrer" target="_blank"><img className="stores-img" src={PlayStore} alt="App store" /></a>
                </div>
              </div>
              <div className="info-card__subtitle">
                ¿En que concesiones puedo usar mi wallet?
              </div>
              <div className="info-card__content">
                <ul className="li-inside-div">
                  <li>ALÓAG</li>
                  <li>CONORTE</li>
                  <li>CONCEGUA</li>
                  <li>EL PAN</li>
                </ul>
              </div>
            </div> */}
          </div>
        </Col>
        <Col md="3">
          <div className="info-card">
            <div className="card-img">
              <img src={CheckBalance} alt="" />
            </div>
            <div className="card-info-body">
              <div className="info-card__title">Consulta de saldo</div>
              <div className="info-card__subtitle">
                ¿Dónde consulto mi saldo?
              </div>
              <div className="info-card__content">
                Podrás consultar en la opción Consulta de saldo y pasadas.{" "}
                <strong>
                  Revisa el saldo de las Concesiones donde tengas una cuenta.
                </strong>
              </div>
              <div className="info-card__subtitle">
                ¿De qué Concesiones puedo consultar mi saldo?
              </div>
              <div className="info-card__content">
                <ul className="li-inside-div">
                  <li>ALÓAG</li>
                  <li>CONORTE</li>
                  <li>CONCEGUA</li>
                  <li>EL PAN</li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col md="3">
          <div className="info-card">
            <div className="card-img">
              <img src={CheckPast} alt="" />
            </div>
            <div className="card-info-body">
              <div className="info-card__title">Consulta de pasadas</div>

              <div className="info-card__subtitle ">
                ¿Dónde puedo consultar mis pasadas?
              </div>
              <div className="info-card__content">
                Podrás consultar en la opción consulta de saldo y pasadas.
                <strong>
                  Revisa tus pasadas en las Concesiones donde tengas una cuenta.
                </strong>
              </div>
              <div className=" info-card__subtitle">
                ¿De qué Concesiones puedo consultar mis pasadas?
              </div>
              <div className="info-card__content">
                <ul className="li-inside-div">
                  <li>ALÓAG</li>
                  <li>CONORTE</li>
                  <li>CONCEGUA</li>
                  <li>EL PAN</li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col md="3">
          <div className="info-card">
            <div className="card-img">
              <img src={Attention} alt="" />
            </div>
            <div className="card-info-body">
              <div className="info-card__title">
                Nuestros canales de atención al cliente
              </div>
              <div className=" info-card__subtitle ">
                Si tienes algún inconveniente, no dudes en comunicarte por alguno
                de nuestros canales de atención.
              </div>

              <div className="info-card__content">
                <ul className="li-inside-div">
                  <li>
                    <strong>Facebook:</strong>{" "}
                    <a href="https://www.facebook.com/TagExpressEcu">
                      www.facebook.com
                    </a>
                  </li>
                  <li>
                    <strong>Correo:</strong> servicioalcliente@tagexpress.club
                  </li>
                  <li>
                    <strong>Whatssap:</strong> Usa nuestro botón de Whatssap
                    para comunicarte con uno de nuestros asesores de manera
                    inmediata.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <WhatssapButton />
    </div>
  );
};

export default Informacion;
