import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  _getUserProfile,
  _getUsuarioData,
} from "../../../../../Controllers/usuarioCtl";
import api from "../../../../../services/api";
import { privHeaders, handleNetworkError } from "../../../../../utils/utils";
import { validNatural, validNoNatural } from "../../../../../utils/validators";
import { ToastContainer } from "react-toastify";
import { notifyWarning } from "../../../../Misc/InfoItems/toasts";
import "react-toastify/dist/ReactToastify.css";
import { Form, FormGroup, Input, Row, Col } from "reactstrap";
import Label from "reactstrap/lib/Label";
import WhatssapButton from "../../../../Misc/WhatssapButton/index";
import TheLoader from "../../../../Misc/TheLoader";
import EditData from "../../../../../Assets/svg/edit_data.svg";
import "./datosDeCuenta.css";

const DatosDeCuenta = () => {
  const history = useHistory();
  const selectRef = useRef(false);

  const [chkEmpresa, setChkEmpresa] = useState(false);
  const [chkfacturacion, setChkfacturacion] = useState(true);
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [direccion, setDireccion] = useState("");
  const [celular, setCelular] = useState("");
  const [correo, setCorreo] = useState("");
  const [identificacion, setIdentificacion] = useState("");
  const [tipoIdent, setTipoIdent] = useState("");

  const [userProfile, setUserProfile] = useState({});
  const [state, setState] = useState({
    reload: false,
    loading: true,
  });

  const reload = () =>
    setState((old) => ({
      reload: !old.reload,
      loading: !old.loading,
    }));

  useEffect(() => {
    getUserData();
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.reload]);

  const getUserData = async () => {
    try {
      setState((old) => ({ ...old, loading: true }));
      const user = await _getUsuarioData();
      setNombres(user.nombres ? user.nombres : "");
      setApellidos(user.apellidos ? user.apellidos : "");
      setDireccion(user.direccion_factura ? user.direccion_factura : "");
      setCelular(user.celular ? user.celular : "");
      setCorreo(user.correo ? user.correo : "");
      setChkEmpresa(user.es_empresa === "S" ? true : false);
      setIdentificacion(user.identificacion ? user.identificacion : "");
      setTipoIdent(
        user.tipo_identificacion !== null
          ? user.tipo_identificacion.descripcion
          : ""
      );
      selectRef.current = user.es_empresa === "S" ? true : false;
      setState((old) => ({ ...old, loading: false }));
    } catch (error) {
      setState((old) => ({ ...old, loading: false }));
      handleNetworkError(error, history);
      notifyWarning("Error al guardar sus datos. Revise el formulario");
    }
  };

  const getUserProfile = async () => {
    try {
      setState((old) => ({ ...old, loading: true }));
      const user = await _getUserProfile();
      setUserProfile(user);
      setState((old) => ({ ...old, loading: false }));
    } catch (error) {
      setState((old) => ({ ...old, loading: false }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState((old) => ({ ...old, loading: true }));
    const es_facturacion = chkfacturacion ? "S" : "N";

    if (selectRef.current) {
      if (
        nombres === "" ||
        direccion === "" ||
        celular === "" ||
        identificacion === "" ||
        correo === ""
      ) {
        setState((old) => ({ ...old, loading: false }));
        notifyWarning("Todos los campos son obligatorios");
        return;
      }
    } else {
      if (
        nombres === "" ||
        apellidos === "" ||
        direccion === "" ||
        celular === "" ||
        identificacion === "" ||
        tipoIdent === "" ||
        correo === ""
      ) {
        setState((old) => ({ ...old, loading: false }));
        notifyWarning("Todos los campos son obligatorios");
        return;
      }
    }
    if (chkEmpresa) {
      if (!validNoNatural(identificacion)) {
        setState((old) => ({ ...old, loading: false }));
        notifyWarning("El RUC debe ser de una empresa (ente jurídico)", {
          autoClose: 4000,
        });
        return;
      }
    } else {
      if (!validNatural(identificacion)) {
        setState((old) => ({ ...old, loading: false }));
        notifyWarning("Cédula o RUC inválido");
        return;
      }
    }

    const data = {
      nombres: nombres,
      apellidos: apellidos,
      direccion: direccion,
      identificacion: identificacion,
      tipo_identificacion: selectRef.current ? "RUC" : tipoIdent,
      telefono: celular,
      correo,
      es_empresa: chkEmpresa ? "S" : "N",
    };

    try {
      await api.put(
        `/api/usuario/cuenta?esfacturacion=${es_facturacion}`,
        data,
        {
          headers: privHeaders(),
        }
      );

      notifyWarning(
        "Sus datos han sido guardados de manera correcta. Ahora podra ver los saldos y pasadas en la opción de consulta de saldos y pasadas",
        { autoClose: 3000 }
      );
      reload();
      setState((old) => ({ ...old, loading: false }));
    } catch (error) {
      setState((old) => ({ ...old, loading: false }));
      handleNetworkError(error, history);
      if (error.response.data.message) {
        notifyWarning(error.response.data.message);
        return;
      }
      notifyWarning("Error al guardar sus datos. Revise el formulario");
      setChkfacturacion(false);
    }
  };

  const checkCompany = (e) => {
    if (e.target.checked) {
      setChkEmpresa(!chkEmpresa);
      selectRef.current = true;
    } else {
      setChkEmpresa(!chkEmpresa);
      selectRef.current = false;
    }
  };

  return (
    <>
      <ToastContainer />
      <label className="page-top-title">Mi cuenta</label>
      {state.loading ? (
        <TheLoader />
      ) : (
        <div className="simple-card-profile">
          <Row>
            <Col md="4" className="profile-data">
              {userProfile.initials ? (
                <>
                  <div className="avatar">
                    <span>
                      <i className="fa fa-user info-icon"></i>
                    </span>
                  </div>
                  <div className="info">
                    <h3>{`${userProfile.usuario}`}</h3>
                  </div>
                </>
              ) : (
                <>
                  <div className="avatar">
                    <span>
                      <i className="fa fa-user info-icon"></i>
                    </span>
                  </div>
                  <div className="info">
                    <h3>{`${userProfile.usuario}`}</h3>
                  </div>
                  <img src={EditData} width="250px" alt="no_data" />
                  <h5 className="info">
                    Por favor complete todos sus datos personales.
                  </h5>
                </>
              )}
            </Col>

            <Col md="8">
              <h2 className="mb-3 mt-5">Editar mis datos</h2>
              <div className="custom-control custom-switch mb-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={chkEmpresa}
                  onChange={checkCompany}
                  id="checkCompany"
                />
                <label htmlFor="checkCompany" className="custom-control-label">
                  <strong>¿Es empresa?</strong>
                </label>
              </div>
              <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <Row>
                  {chkEmpresa ? (
                    <Col md="12">
                      <FormGroup>
                        <Label>
                          Nombre de la empresa{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          maxLength="50"
                          type="text"
                          id="nomEmpresa"
                          value={nombres}
                          placeholder="Nombre de la empresa"
                          onChange={(e) => setNombres(e.target.value)}
                          required
                        />
                      </FormGroup>
                    </Col>
                  ) : (
                    <>
                      <Col md="6">
                        <FormGroup>
                          <Label>
                            Nombres <span className="text-danger">*</span>
                          </Label>
                          <Input
                            maxLength="50"
                            type="text"
                            id="nombres"
                            value={nombres}
                            placeholder="Nombres"
                            onChange={(e) => setNombres(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>
                            Apellidos <span className="text-danger">*</span>
                          </Label>
                          <Input
                            maxLength="50"
                            type="text"
                            id="apellidos"
                            value={apellidos}
                            placeholder="Apellidos"
                            onChange={(e) => setApellidos(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>
                        Tipo de Identificación{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        id="tipo"
                        onChange={(e) => setTipoIdent(e.target.value)}
                        required
                        value={selectRef.current ? "RUC" : tipoIdent}
                        disabled={selectRef.current}
                      >
                        <option value="">Seleccione</option>
                        <option value="RUC">RUC</option>
                        <option value="CÉDULA">CÉDULA</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>
                        Identificación <span className="text-danger">*</span>
                      </Label>
                      <Input
                        maxLength="13"
                        type="text"
                        placeholder="Identificación"
                        id="identificacion"
                        value={identificacion}
                        onChange={(e) => setIdentificacion(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Dirección</Label>
                      <Input
                        maxLength="100"
                        type="text"
                        placeholder="Dirección"
                        id="direccion"
                        value={direccion}
                        onChange={(e) => setDireccion(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Teléfono</Label>
                      <Input
                        maxLength="10"
                        type="text"
                        id="celular"
                        value={celular}
                        placeholder="Teléfono"
                        onChange={(e) => setCelular(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Usuario</Label>
                      <Input
                        type="email"
                        id="correo"
                        value={correo}
                        placeholder="Correo electrónico"
                        onChange={(e) => setCorreo(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="d-flex justify-content-between mb-3">
                  <span className="text-danger">* Campos obligatorios</span>

                  {/* <div className="d-flex justify-content-between">
                    <div className="custom-control custom-switch mr-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={chkfacturacion}
                        onChange={() => setChkfacturacion(!chkfacturacion)}
                        id="checkInvoice"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkInvoice"
                      >
                        <strong>Usar estos datos para facturación</strong>
                      </label>
                    </div>
                  </div> */}
                </div>
                <div className="d-flex justify-content-start">
                  <button className="btonbilletera bton-yellow bton-sm">
                    Guardar
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      )}

      <WhatssapButton />
    </>
  );
};

export default DatosDeCuenta;
