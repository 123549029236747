import "./HeaderPage.css";

function HeaderPage({ urlImg, title, backgroundPosition = "center" }) {
  return (
    <div className="container-banner" style={{ marginTop: "5rem" }}>
      <div
        className="banner"
        style={{
          backgroundImage: `
        linear-gradient(
            to bottom,
            rgba(239, 239, 239, 0.166),
            rgba(16, 16, 16, 0.786)
          ),
          url(${urlImg})
        `,
          backgroundPosition: backgroundPosition,
        }}
      ></div>
      <h1 className="text-banner">{title}</h1>
      <div className="indicador" />
    </div>
  );
}
export default HeaderPage;
