import axios from 'axios';

const env = process.env.NODE_ENV;

const api = axios.create({
  // baseURL: env === 'production' ? 'https://www.tagexpress.club/' : 'http://localhost:8000',
  baseURL: env === 'production' ? 'https://dev.tagexpress.club/' : 'http://localhost:8000',
});

export default api;
