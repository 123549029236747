import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Components/Pages/Login/LoginLanding";
import PublicLanding from "./Components/Pages/publicLanding";
// import Advertising from "./Components/Misc/Advertising";
import Dashboard from "./Components/Layout/Dashboard/index";
import ProtectedRoute from "./utils/protectedRoute";
import ConfirmCuenta from "./Components/Pages/ConfirmCuenta/index";
import PasswdRecForm from "./Components/Pages/ResetPassword/PasswdRecForm/index";
import PasswdRecUpdate from "./Components/Pages/ResetPassword/passwdRecUpdate/index";
import ErrorPage from "./Components/Pages/ErrorPage";
import SecurityPolitics from "./Components/Pages/publicLanding/components/SecurityPolitics/SecurityPolitics";
import FrequentQuestions from "./Components/Pages/FrequentQuestions/FrequentQuestions";
import Rate from "./Components/Pages/Rate/Rate";
import RoadConditions from "./Components/Pages/RoadConditions/RoadConditions";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={PublicLanding} />
        <Route path="/login" exact component={Login} />
        <Route path="/fq" exact component={FrequentQuestions} />
        <Route path="/tarifas" exact component={Rate} />
        <Route path="/estado-vias" exact component={RoadConditions} />
        <Route path="/politicas" exact component={SecurityPolitics} />
        <Route
          path="/usuarios/confirmacion/cuenta/:user"
          component={ConfirmCuenta}
        />
        <Route exact path="/usuarios/password" component={PasswdRecForm} />
        <Route
          exact
          path="/usuarios/password/reset/:codigo"
          component={PasswdRecUpdate}
        />
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <Route exact path="/500" component={() => <ErrorPage errorCode="" />} />
        <Route path="*" component={() => <ErrorPage errorCode="404" />} />
      </Switch>
    </Router>
  );
};

export default Routes;
