import NavLayout from "../../Layout/Nav";
import HeaderPage from "../../Misc/HeaderPage/HeaderPage";
import { Footer } from "../publicLanding/components";
import BannerImg from "../../../Assets/banner-rate.jpg";

import TableRate from "./components/TableRate/TableRate";

function Rate() {
  return (
    <div>
      <NavLayout />
      <HeaderPage urlImg={BannerImg} title="Tarifas" />
      <div className="container" style={{ marginTop: 50 }}>
        <TableRate />
      </div>
      <Footer />
    </div>
  );
}
export default Rate;
