import { toast } from "react-toastify";

function notifyError(message, params) {
  toast.error(message, params || { autoClose: 4000 });
}

function notifyOk(message, params) {
  toast.success(message, params || { autoClose: 2000 });
}

function notifyInfo(message, params) {
  toast.info(message, params || { autoClose: 2000 });
}

function notifyWarning(message, params) {
  toast.warning(message, params || { autoClose: 2000 });
}

export { notifyOk, notifyError, notifyInfo, notifyWarning };
