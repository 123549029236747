import React from 'react';
import { NavLink } from 'react-router-dom';
import './notFound.css';
import { ReactComponent as ErrorServer } from '../../../Assets/Error_Server.svg';


const ErrorPage = (props) => {
  return (
    <div id="notFound" className="wrapper">
      <div className="content">
        <div className="content__top">
          {props.errorCode}
        </div>
        <div className="content__bottom">
          {props.errorCode === '404' ? 'Lo sentimos. Esa página no existe :(' :
            <div>
              <h1 className="content__title">Lo sentimos, Hubo un problema en nuestros servidores. Por favor, notifíquelo a <br />servicioalcliente@tagexpress.club</h1>
              <ErrorServer className="content__image" />
            </div>
          }
        </div>
      </div>
      <div className="content__link">
        <NavLink to="/" >
          <label className="content__link--text">
            Regresar a terreno conocido
          </label>
        </NavLink>
      </div>
    </div>
  );
};

export default ErrorPage;