import React from 'react';
import logo from '../../../../../Assets/logo-con-frase.png';
const SecurityPolitics = () => {
    return (
        <div className='container'>
            <img src={logo} alt='tagexpress' />
            <p>
                Al crear una cuenta esta de acuerdo con nuestras políticas.
                Calprandina, informa a los usuarios de la
                aplicación wallet tagexpress acerca del tratamiento de los datos personales, que ellos
                voluntariamente hayan facilitado durante el proceso de registro, acceso y utilización del servicio.
                1. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO.
                Calprandina, y domicilio a efectos de notificaciones en:
                Ecuador e inscrita en el Registro Mercantil de Quito,
                es la entidad responsable del tratamiento de los datos facilitados por los clientes de la Aplicación.
                2. FINALIDAD DEL TRATAMIENTO DE DATOS.
                Para proceder al registro, acceso y posterior uso de la Aplicación, el Usuario deberá facilitar -de forma voluntaria-, datos de
                carácter personal, los cuales serán incorporados a soportes automatizados titularidad de Calprandina.
                La recogida, almacenamiento, modificación, estructuración y en su caso, eliminación, de los datos proporcionados por los
                Usuarios, constituirán operaciones de tratamiento llevadas a cabo por el Responsable, con la finalidad de garantizar el
                correcto funcionamiento de la Aplicación, mantener la relación de prestación de servicios y/o comercial con el Usuario, y
                para la gestión, administración, información, prestación y mejora del servicio.
                Los datos personales facilitados por el Usuario -especialmente, el correo electrónico o e-mail- podrán emplearse también
                para remitir boletines (newsletters), así como comunicaciones comerciales de promociones y/o publicidad de la Aplicación,
                siempre y cuando, el Usuario haya prestado previamente su consentimiento expreso para la recepción de estas
                comunicaciones vía electrónica.

                La política de privacidad de Calprandina para la aplicación móvil es la siguiente:
                Recopilación de información: Al utilizar la aplicación, recopilamos información personal sobre usted, como su nombre, dirección de correo electrónico, número de teléfono y dirección de facturación. También recopilamos información sobre sus transacciones y el uso de la aplicación.
                Uso de la información: Utilizamos la información recopilada para proporcionar y mejorar nuestros servicios, incluyendo la ejecución de transacciones y la verificación de la identidad del usuario. También podemos utilizar la información para comunicarnos con usted y enviarle correos electrónicos y mensajes de texto con respecto a su cuenta y para enviarle promociones y ofertas.
                Seguridad de la información: Tomamos medidas de seguridad razonables para proteger su información personal contra el acceso no autorizado y la alteración, divulgación o destrucción accidental. Sin embargo, no podemos garantizar la seguridad absoluta de su información y le animamos a tomar medidas adicionales para proteger su cuenta, como utilizar una contraseña segura.
                Enlaces a otros sitios: La aplicación puede contener enlaces a sitios web de terceros. No somos responsables de las prácticas de privacidad de dichos sitios y le recomendamos que revise las políticas de privacidad de cada sitio que visite.
                Cambios a esta política: Podemos actualizar esta política de privacidad de vez en cuando. Le informaremos de cualquier cambio importante publicando una nueva versión en esta página. Le recomendamos que revise esta página periódicamente para estar al tanto de cualquier cambio.
                Contacto: Si tiene alguna pregunta o inquietud con respecto a nuestra política de privacidad, no dude en ponerse en contacto con nosotros a través de nuestro sitio web o enviando un correo electrónico a servicioalcliente@tagexpress.club.
            </p>
        </div>
    )

}

export default SecurityPolitics;