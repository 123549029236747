import NavLayout from "../../Layout/Nav";
import HeaderPage from "../../Misc/HeaderPage/HeaderPage";
import { Faq, Footer } from "../publicLanding/components";
import BannerImg from "../../../Assets/banner-question.jpg";

function FrequentQuestions() {
  return (
    <div>
      <NavLayout />
      <HeaderPage urlImg={BannerImg} title="Preguntas frecuentes" />
      <Faq />
      <Footer />
    </div>
  );
}
export default FrequentQuestions;
