import React, { useState } from 'react';
import api from "../../../../services/api";
import { ToastContainer } from 'react-toastify';
import { notifyWarning, notifyError } from '../../../Misc/InfoItems/toasts';
import { pubHeaders } from "../../../../utils/utils";
import logoTaxExpress from "../../../../Assets/logo-sin-fondo-tag-express.png";
import WhatssapButton from '../../../Misc/WhatssapButton';
import {
  Container,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import "./passrecform.css";

const PasswdRecForm = () => {
  const [email, setEmail] = useState("");


  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      email
    }

    if (email.trim() === "") {
      notifyError("Debe ingresar su correo");
      return;
    }

    try {
      await api.post("/api/usuarios/passwd", data, {
        headers: pubHeaders(),
      });
      notifyWarning("Por favor revise su buzón de correo o sección de spam para restaurar su contraseña", { autoClose: 7000 });

    } catch (error) {
      notifyError("correo incorrecto");
    }

  }

  return (
    <>
      <ToastContainer toastStyle={{ backgroundColor: "#FBC600", color: "#1C3C86" }} />
      <div className="screen-container">
        <Container className="center-card">
          <Card>
            <CardHeader className='text-center'><img className="logo-center" style={{ height: 50 }} src={logoTaxExpress} alt="logo" /></CardHeader>
            <CardBody className="text-center">
              <CardTitle tag="h2"><strong>Restauración de contraseña</strong></CardTitle>
              <CardText>
                <strong>Por favor ingresa tu correo para restaurar tu contraseña</strong>
              </CardText>
              <div className="passwdForm responsive-form">
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Input
                      type="text"
                      name="user"
                      id="user"
                      placeholder="Correo"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>

                  <button
                    style={{ cursor: "pointer" }}
                    className="bton bton-yellow bton-sm login-btn"
                  >
                    Restaurar contraseña
                  </button>
                </Form>
              </div>
            </CardBody>
            <CardFooter className="text-center">Para cualquier duda o problema en el proceso por favor comunicarse a este correo <strong> servicioalcliente@tagexpress.club</strong></CardFooter>
          </Card>
        </Container>
        <WhatssapButton />
      </div>
      <WhatssapButton />
    </>
  )
}

export default PasswdRecForm