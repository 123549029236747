import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { concesiones } from "../../../../../utils/constants";

const ConsultCard = ({ concesion, client }) => {
  return (
    <Card style={{ border: "2px solid rgba(0,0,0,.125)" }}>
      <CardHeader className="platesub">
        <strong>{concesiones[concesion]}</strong>{" "}
        <i className="fa fa-car mt-1" style={{ float: "right" }}></i>
      </CardHeader>
      <CardBody className="balances-body">
        <div>
          <p
            className={`${
              client.saldos[concesion] ? "balance-amount" : ""
            } balance`}
            style={{ textAlign: "center" }}
          >
            {client.saldos[concesion]
              ? `$${Number(client.saldos[concesion].replace(",", ".")).toFixed(
                  2
                )}`
              : "Información no disponible"}
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

export default ConsultCard;
