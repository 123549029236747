import React from "react";
import { Fade } from "react-awesome-reveal";
import { Card } from "../index";
import clockTimer from "../../../../../Assets/clock-timer.png";
import dashboard from "../../../../../Assets/dashboard.png";
import quality from "../../../../../Assets/quality.png";
import readingDistance from "../../../../../Assets/reading-distance.png";
// import money from "../../../../../Assets/money.png";
// import wallet from "../../../../../Assets/wallet-tag.png";

export const OurBenefits = () => {
  return (
    <div className="container mt-5">
      <h2 className="title2 text-center">NUESTROS BENEFICIOS</h2>
      <p className="sub-text"></p>
      <Fade top>
        <div className="row">
          <div className="col-md-4">
            <Card
              item={{
                img: clockTimer,
                title: "",
                content:
                  "Disminución de tiempo en la cola de las estaciones de peaje",
              }}
            />
          </div>
          <div className="col-md-4">
            <Card
              item={{
                img: dashboard,
                title: "",
                content:
                  "Pagar con un sólo saldo en peajes asociados a través de la wallet",
              }}
            />
          </div>
          <div className="col-md-4">
            <Card
              item={{
                img: readingDistance,
                title: "",
                content: "Cruza el peaje sin detenerte",
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Card
              item={{
                img: quality,
                title: "",
                content: "Consulta el histórico de tu tag en segundos",
              }}
            />
          </div>
          {/* <div className="col-md-4">
            <Card
              item={{
                img: money,
                title: "",
                content:
                  "Pagar con el saldo de tu tag en parqueaderos asociados",
              }}
            />
          </div> */}
          <div className="col-md-4">
            <Card
              item={{
                img: readingDistance,
                title: "",
                content: "Asistencia vial gratis de grúas y ambulancias",
              }}
            />
          </div>
        </div>
      </Fade>
    </div>
  );
};
