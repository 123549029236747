import React from 'react';
import './whatssapBtn.css';
import whatsapp from "../../../Assets/whatsapp.png";

// Takes user to page's top
const WhatssapButton = () => {
  return (
    <div id="whatssap-image">
      <a href="https://wa.link/2n2ic5" target="_blank" rel="noopener noreferrer">
        <img src={whatsapp} alt="whatssap icon" />
      </a>
    </div>
  );
};

export default WhatssapButton;