import moment from 'moment';
// HTTP public and private section headers templates
const pubHeaders = function () {
  return {
    'Content-Type': 'application/json',
  }
}


const privHeaders = function () {
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("t")}`
  }
}


const Filevalidation = (image, limit) => {
  let fileSize = Math.round((image.size / 1024));
  // Up to n bytes
  if (fileSize >= limit) {
    return false;
  }
  return true;
}

function logout() {
  window.localStorage.removeItem('t');
  window.location.replace('/login');
}


function handleNetworkError(error, history) {
  const errorResp = error.response;

  if (!errorResp) {
    return;
  }

  // If there is a data field, use it
  if (errorResp.data.data) {
    switch (errorResp.data.data) {
      case 'TOKEN_INVALIDO':
        // console.log('TOKEN_INVALIDO')
        logout();
        break;
      case 'TOKEN_EXPIRADO':
        // console.log('TOKEN_EXPIRADO')
        // Later, make this the trigger for the token refresh process
        logout();
        break;
      case 'NO_AUTENTICADO':
        // console.log('NO_AUTENTICADO')
        logout();
        break;
      case 'NO_AUTORIZADO':
        // console.log('NO_AUTORIZADO')
        logout();
        break;
      case 'ERROR_SERVIDOR':
        // console.log('ERROR_SERVIDOR')
        history.replace('/500');
        break;
      default:
        break;
    }
  }
  // If there is not a data field, use the status code
  else if (errorResp.status) {
    switch (errorResp.status) {
      case 404:
        history.replace('/404'); // This route will point to 404 screen because there is none route called like that (/404)
        break;
      case 500:
        // console.log('ERROR_SERVIDOR')
        history.replace('/500'); // This route (and page) must be created
        break;
      default:
        break;
    }
  }
}

const esMayorDeEdad = (fecha_nacimiento, edad_referencia) => {
  if (moment(fecha_nacimiento).add(edad_referencia, 'years').toDate() < new Date()) {
    return true;
  } else {
    return false;
  }
}

const validateMonto = (monto) => {
  let montoMaximo = 500;
  if (monto > montoMaximo) {
    return true;
  } else {
    return false;
  }
}

function chkPasswdPolicy(passwd) {
  const passValidate = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@?$%^&*()_+])[0-9a-zA-Z!@?$%^&*()_+]{12,30}$/.test(passwd);
  if (passValidate) {
    return true;
  }
  return false;
}

export {
  pubHeaders,
  privHeaders,
  Filevalidation,
  handleNetworkError,
  logout,
  esMayorDeEdad,
  validateMonto,
  chkPasswdPolicy,
}
