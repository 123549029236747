import api from '../services/api';
import { privHeaders } from '../utils/utils';


async function _getUsuarioData() {
  const resp = await api.get('/api/usuarios/datosPersonales', { headers: privHeaders() });
  return resp.data;
}

async function _getDataInvoice() {
  const resp = await api.get('/api/usuarios/facturacion', { headers: privHeaders() });
  return resp.data;
}

async function _getUserProfile() {
  const resp = await api.get('/api/usuario/me/profile', { headers: privHeaders() });
  return resp.data;
}

async function _updatePasswd(data) {
  const resp = await api.put('/api/usuarios/passwd', data, { headers: privHeaders() });
  return resp.data;
}


export {
  _getUsuarioData,
  _updatePasswd,
  _getUserProfile,
  _getDataInvoice
}