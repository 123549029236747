import React, { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import NavLayout from "../../Layout/Nav";
import GoUpButton from "../../Misc/GoUpButton";
import WhatssapButton from "../../Misc/WhatssapButton";
import modalImg from "../../../Assets/cloud-modal.jpeg";
import {
  AboutUs,
  Contact,
  Footer,
  TagExpressCarousel,
  SellingPoint,
  WhatIsTagexpress,
  OurBenefits,
  Certification,
} from "./components";
import "./publicLanding.css";

const PublicLanding = () => {
  const [openModal, setOpenModal] = useState(true);

  const Skip = () => {
    setOpenModal(false);
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <Modal isOpen={openModal} centered className="modal-init">
        <ModalBody>
          <div className="presentation">
            <img src={modalImg} alt="Puntos de venta de tags" />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            <Button color="warning" outline onClick={Skip}>
              Saltar
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <NavLayout />
      <TagExpressCarousel />
      <SellingPoint />
      <WhatIsTagexpress />
      <OurBenefits />
      <AboutUs />
      <Contact />
      {/* <RechargePoint /> */}
      {/* <DownloadApp /> */}
      <GoUpButton />
      <WhatssapButton />
      <Certification />
      <Footer />
    </div>
  );
};

export default PublicLanding;
