import React from "react";
import { Fade } from "react-awesome-reveal";
import { CardSlide } from "../../../../Misc/Slideshow/CardSlide";
import { Slideshow } from "../../../../Misc/Slideshow/Slideshow";
// import oficinaTag from "../../../../../Assets/oficinaTag.jpeg";
// import peajeAloag from "../../../../../Assets/peaje_aloag.jpg";
// import peajeElTriunfo from "../../../../../Assets/peaje_el_triunfo.jpg";
import phamarcy from "../../../../../Assets/pharmacysLogo2.jpg";
import cruzazul from "../../../../../Assets/cruz_azul.jpg";
import tia from "../../../../../Assets/tia.png";
import rappi from "../../../../../Assets/rappi.png";
import "./sellingPoint.css";

export const SellingPoint = () => {
  const itemsPhysicalPointsOfSale = [
    {
      src: `${phamarcy}`,
      altText: "Farmacia",
      caption: "Pharmacys(Guayaquil, Quito y Vía Samborondón)",
      header: "PHARMACY'S",
    },
    {
      src: `${cruzazul}`,
      altText: "Cruz Azul",
      caption:
        "Farmacias Cruz Azul(Milagro, El Triunfo, Naranjal, Yaguachi y Daule)",
      header: "CRUZ AZUL",
    },

    {
      src: `${tia}`,
      altText: "tia",
      caption: "Tienda virtual de Almacenes Tía",
      header: "TÍA",
    },
    {
      src: `${rappi}`,
      altText: "Rappi",
      caption: "Rappi(Vía Samborondón y Peaje Autopista General Rumiñahui)",
      header: "RAPPI",
    },
  ];
  return (
    <div className="container mt-5 mb-5">
      <h2 className="title2 text-center">PUNTOS DE VENTA</h2>
      <p className="sub-text"></p>
      <Fade top>
        <div
          id="puntosVentas"
          className="puntos-ventas"
          style={{ paddingTop: 20 }}
        >
          <Slideshow>
            {itemsPhysicalPointsOfSale.map((item) => (
              <CardSlide
                key={item.altText}
                src={item.src}
                header={item.header}
                altText={item.altText}
                caption={item.caption}
              />
            ))}
          </Slideshow>
        </div>
      </Fade>
      <p className="my-3">
        <strong>IMPORTANTE:</strong> ¡Evita ser estafado! Solamente adquiere los
        tags en los puntos de venta autorizados que se muestran aquí.
      </p>
    </div>
  );
};
