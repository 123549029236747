import React, { useState, useEffect } from 'react';
import './goUpButton.css';
import { CornerLeftUp } from 'react-feather';

// Takes user to page's top
const GoUpButton = () => {
  const [show, setShow] = useState();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  function handleScroll() {
    if (window.scrollY > 100) {
      setShow(true);
    } else {
      setShow(false);
    }
  }

  function goUp() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    show ?
      <div id="goUpButton" onClick={goUp}>
        <CornerLeftUp color="#fff" size={25} />
      </div>
      : null
  );
};

export default GoUpButton;