import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import './alertMessage.css'

const AlertMessage = (props) => {
  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  return (
    <Alert className="success-message" isOpen={visible} toggle={onDismiss}>
      <p>
        {props.text}
      </p>
    </Alert>
  );
}

export default AlertMessage;