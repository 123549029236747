import React from "react";

export const CardSlide = ({ src, altText, header, caption }) => {
  return (
    <div className="simple-card punto-venta">
      <img src={src} alt={altText} className="rounded" />
      <h6 className="text">{header}</h6>
      <p className="serviceText">{caption}</p>
    </div>
  );
};
