import React from "react";
import { typeTransaction } from "../../../../../utils/constants";

const ConsultTable = ({ clientPasadas, client }) => {
  // console.log(clientPasadas);
  return (
    <table className="report-table" style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Placa</th>
          <th>Categoría</th>
          <th>Vía</th>
          <th>Peaje</th>
          <th>Monto cobrado</th>
          <th>Tipo de transacción</th>
          <th>Saldo</th>
          <th>Observación</th>
        </tr>
      </thead>
      <tbody>
        {clientPasadas[client.clienteId].map((pasada, index) => {
          return (
            <tr key={index}>
              <td>{pasada.HORA}</td>
              <td>{pasada.PLACA}</td>
              <td>{pasada.ID_CATEGORIA}</td>
              <td>{pasada.ID_VIA}</td>
              <td>{pasada.NOMBRE_PEAJE}</td>
              <td>${Number(pasada.MONTO.replace(",", ".")).toFixed(2)}</td>
              <td>{typeTransaction(pasada.TIPO)}</td>
              <td>${pasada.SALDO}</td>
              <td>{pasada.OBSERVACION.split(" /")}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ConsultTable;
