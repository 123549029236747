import React from "react";
import { Fade } from "react-awesome-reveal";
import SideIconCard from "../../../../Misc/SideIconCard/SideIconCard";
import iso9001 from "../../../../../Assets/process.png";
import iso14001 from "../../../../../Assets/planet-earth.png";
import iso27001 from "../../../../../Assets/cyber-security.png";
import iso45001 from "../../../../../Assets/workers.png";
import checked from "../../../../../Assets/checked-certs.png";

export const Certification = () => {
  return (
    <div className="container mt-5">
      <h2 className="title2 text-center">NUESTRAS CERTIFICACIONES</h2>
      <Fade top>
        <div className="row mt-5">
          <div className="col-md-6">
            <SideIconCard
              img={iso9001}
              checked={checked}
              title="ISO 9001: 2015"
              content={"Sistemas de gestión de la calidad"}
            />
          </div>
          <div className="col-md-6">
            <SideIconCard
              img={iso14001}
              checked={checked}
              title="ISO 1401: 2015"
              content={"Sistemas de gestión ambiental"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <SideIconCard
              img={iso27001}
              checked={checked}
              title="ISO 27001: 2015"
              content={"Sistemas de gestión de la seguridad de la información"}
            />
          </div>
          <div className="col-md-6">
            <SideIconCard
              img={iso45001}
              checked={checked}
              title="ISO 45001: 2018"
              content={
                "Sistemas de gestión de la seguridad y salud en el trabajo"
              }
            />
          </div>
        </div>
      </Fade>
    </div>
  );
};
