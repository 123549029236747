import React from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import Logo from "../../../../../Assets/logo-con-frase.png";
import pdfSGI from "../../../../../Assets/pdf/SGI-COMUNICADO-INTERNO.pdf";

import "./footer.css";

export const Footer = () => {
  return (
    <Fade>
      <section className="footer container mt-5">
        <div className="te-container-footer">
          <div className="footer-left">
            <img src={Logo} alt="logo-tag-express" />
            <p className="paragraph">
              Como empresa buscamos facilitar la movilidad, marcar la diferencia
              al conectar estaciones de peaje, garantizar accesibilidad a los
              servicios y ofrecer la mejor experiencia al revisar su gestión de
              cuenta de usuario.
            </p>
          </div>
          <div className="footer-rigth">
            <div className="links">
              <h2 className="subtitle">Enlaces útiles</h2>
              <ul>
                <li>
                  <a href="#nosotros">Nosotros</a>
                </li>
                <li>
                  <a href="#puntosVentas">Puntos de venta</a>
                </li>
                <li>
                  <a href="#preguntas">Preguntas frecuentes</a>
                </li>
                <li>
                  <a href="#wallet">Wallet</a>
                </li>
                <li>
                  <a href="#contacto">Contacto</a>
                </li>
                <li>
                  <a href={pdfSGI} target="_blank" rel="noreferrer">
                    Liderazgo y compromiso
                  </a>
                </li>
              </ul>
            </div>
            <div className="contact">
              <h2 className="subtitle">Contáctanos</h2>
              <p>Peaje Autopista General Rumiñahui</p>
              <p>
                <strong>Teléfono:</strong> +593-98-558-7634 / 022-600-686
              </p>
              <p>
                <strong>Correo:</strong> servicioalcliente@tagexpress.club
              </p>
            </div>
            <div className="social-media">
              <h2 className="subtitle">Redes Sociales</h2>
              <div className="icons">
                <div className="social-icons">
                  <a
                    href="https://www.facebook.com/TagExpressEcu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </div>
                <div className="social-icons">
                  <a
                    href="https://www.instagram.com/tag.express.ecuador/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="copyright">
          <Link to={"/politicas"} target="_blank">
            Politicas de privacidad
          </Link>
        </div>
        <div className="copyright mb-2">
          © 2019 VISIONTECHNOLOGY. Todos los derechos reservados. | Powered by{" "}
          <a href="https://www.visiontechnology.website" target="_blanck">
            Visiontechnology
          </a>
        </div>
      </section>
    </Fade>
  );
};
