import React, { useEffect } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { useUserContext } from "../../../Context/UserContext";
import ActualizarPasswdPage from "../../Pages/Dashboard/ActualizarPasswd";
import Informacion from "../../Pages/Dashboard/Informacion/index";
import logoSinFrase from "../../../Assets/logo-sin-frase.png";
import ConsultaMacro from "../../Pages/Dashboard/ConsultasMacro/index";
import DatosDeCuenta from "../../Pages/Dashboard/MiCuenta/DatosDeCuenta";

import "./dashboard.css";
import { logout } from "../../../utils/utils";
import { TollInformation } from "../../Pages/Dashboard/TollInformation";
import Homologacion from "../../Pages/Dashboard/Homologacion";
import CrearHomologacion from "../../Pages/Dashboard/CrearHomologacion";

const Dashboard = () => {
  const context = useUserContext();

  useEffect(() => {
    // To hide the sidebar when an option is clicked (mobile view: < 600px)
    document.addEventListener("click", (event) => {
      if (
        event.target.classList.contains("menu-option-text") &&
        window.screen.width <= 600
      ) {
        document
          .getElementsByClassName("page-wrapper")[0]
          .classList.remove("toggled");
      }
    });
    // ___________________NO BORRAR ESTO. SE USARA CUANDO SE IMPLEMENTEN DROPDOWNS__________________
    let dropDowns = document.querySelectorAll(".sidebar-dropdown > span");
    dropDowns.forEach((i) => {
      i.addEventListener("click", function () {
        if (i.parentElement.classList.contains("active")) {
          if (this.nextElementSibling) {
            this.nextElementSibling.classList.remove("displayed-submenu");
          }

          i.parentElement.classList.remove("active");
        } else {
          if (this.nextElementSibling) {
            this.nextElementSibling.classList.add("displayed-submenu");
          }

          i.parentElement.classList.add("active");
        }
      });
    });

    // side bar show or hide
    document
      .getElementById("close-sidebar")
      .addEventListener("click", function () {
        document
          .getElementsByClassName("page-wrapper")[0]
          .classList.remove("toggled");
      });

    document
      .getElementById("show-sidebar")
      .addEventListener("click", function () {
        document
          .getElementsByClassName("page-wrapper")[0]
          .classList.add("toggled");
      });
  }, []);

  return (
    <div className="page-wrapper chiller-theme toggled">
      <button
        id="show-sidebar"
        className="btn btn-sm btn-dark"
        style={{ zIndex: 100 }}
      >
        <i style={{ color: "#31353D" }} className="fa fa-bars"></i>
      </button>

      <nav id="sidebar" className="sidebar-wrapper">
        <div className="sidebar-content">
          <div className="sidebar-brand">
            <Link to="/">
              <img
                src={logoSinFrase}
                alt="tagexpress logo"
                className="logoNav"
              />
            </Link>
            <div id="close-sidebar">
              <i className="fa fa-times" style={{ color: "var(--blueTG)" }}></i>
            </div>
          </div>

          <div className="sidebar-header">
            <div className="user-info ml-4">
              <span className="user-name">{context.user.split("@")[0]}</span>
              <span className="user-status">
                <span style={{ color: "var(--yellow)" }}>
                  <strong>En línea</strong>
                </span>
              </span>
            </div>
          </div>

          <div className="sidebar-menu">
            <ul>
              <li className="header-menu">
                <span style={{ fontSize: "20px" }}>General</span>
              </li>

              {/*Ejemplo de dropdown */}

              <li className="sidebar-dropdown">
                <span style={{ cursor: "pointer" }}>
                  <Link
                    className="menu-option-text"
                    to="/dashboard/informacion"
                  >
                    <i className="fa fa-info"></i>
                    Información
                  </Link>
                </span>
              </li>
              <li className="sidebar-dropdown">
                <span style={{ cursor: "pointer" }}>
                  <Link
                    className="menu-option-text"
                    to="/dashboard/informacion-peajes"
                  >
                    <i className="fa fa-map"></i>
                    Información de peajes
                  </Link>
                </span>
              </li>

              <li className="sidebar-dropdown">
                <span style={{ cursor: "pointer" }}>
                  <Link className="menu-option-text" to="/dashboard/consultas">
                    <i className="fa fa-search"></i>
                    Consulta de saldo y pasadas
                  </Link>
                </span>
              </li>

              <li className="sidebar-dropdown">
                <span style={{ cursor: "pointer" }}>
                  <Link
                    className="menu-option-text"
                    to="/dashboard/homologacion"
                  >
                    <i className="fa fa-search"></i>
                    Homologación
                  </Link>
                </span>
              </li>

              <li className="sidebar-dropdown">
                <span>
                  <a style={{ cursor: "pointer" }} href>
                    <i className="fa fa-user"></i>
                    Mi cuenta
                  </a>
                </span>
                <div className="sidebar-submenu">
                  <ul>
                    <li className="sidebar-dropdown">
                      <span style={{ cursor: "pointer" }}>
                        <Link
                          className="menu-option-text"
                          to="/dashboard/datos/cuenta"
                        >
                          <i className="fa fa-user"></i>
                          Datos de cuenta
                        </Link>
                      </span>
                    </li>

                    <li className="sidebar-dropdown">
                      <span style={{ cursor: "pointer" }}>
                        <Link
                          className="menu-option-text"
                          to="/dashboard/cambiarcontraseña"
                        >
                          <i className="fa fa-unlock-alt"></i>
                          Cambiar contraseña
                        </Link>
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="sidebar-footer pl-2">
          <div className="text-center">
            <button
              onClick={logout}
              style={{ cursor: "pointer" }}
              className="btonexit bton-sm"
            >
              <i
                className="fa fa-arrow-circle-left pr-2"
                style={{ fontSize: "18px" }}
                aria-hidden="true"
              ></i>
              <strong>Salir</strong>
            </button>
          </div>
        </div>
      </nav>

      <main className="page-content">
        <div className="container-fluid">
          <Switch>
            <Route path="/dashboard/informacion">
              <Informacion />
            </Route>

            <Route path="/dashboard/informacion-peajes">
              <TollInformation />
            </Route>

            <Route path="/dashboard/consultas">
              <ConsultaMacro />
            </Route>

            <Route path="/dashboard/homologacion">
              <Homologacion />
            </Route>

            <Route path="/dashboard/crear-homologacion">
              <CrearHomologacion />
            </Route>

            <Route path="/dashboard/datos/cuenta">
              <DatosDeCuenta />
            </Route>

            <Route
              path="/dashboard/cambiarcontraseña"
              component={ActualizarPasswdPage}
            />
          </Switch>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
