import React, { useState, useEffect } from "react";
import api from "../../../../services/api";
import { useHistory } from "react-router-dom";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import TheLoader from "../../../Misc/TheLoader";
import { ToastContainer } from "react-toastify";
import { notifyWarning } from "../../../Misc/InfoItems/toasts";
import "react-toastify/dist/ReactToastify.css";

import { privHeaders, handleNetworkError } from "../../../../utils/utils";
import { getDate, calculateDays } from "../../../../utils/dates";
import * as XLSX from "xlsx";
import excelLogo from "../../../../Assets/Excel-Logo.png";

import WhatssapButton from "../../../Misc/WhatssapButton/index";
import AlertMessage from "../../../Misc/AlertMessage/index";
import "./consultaMacro.css";
import ConsultCard from "./components/ConsultCard";
import ConsultTable from "./components/ConsultTable";
import moment from "moment";
import conorte from "../../../../Assets/conorte.png";
import concegua from "../../../../Assets/concegua.png";

const ConsultaMacro = () => {
  const history = useHistory();

  const [data, setData] = useState({
    client: [],
    clientAloag: [],
    clientPasadas: [],
    clientPasadasById: null,
    clientMultipleAccounts: [],
    loading: false,
  });

  const [initDate, setInitDate] = useState(getDate(false));
  const [finalDate, setFinalDate] = useState(getDate(false));
  const [concesion, setConcesion] = useState("conorte");
  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    getClientMultipleAcount();
    // eslint-disable-next-line
  }, [history]); // Dependency of getClientData

  const getClientMultipleAcount = async () => {
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await api.get("/api/siryc/clienteMultipleAccount", {
        headers: privHeaders(),
      });
      setData((old) => ({
        ...old,
        clientMultipleAccounts: response.data,
        loading: false,
      }));
      if (response.data.length < 3) {
        getClientSaldoData();
      } else {
        setData((old) => ({ ...old, loading: false }));
      }
    } catch (error) {
      setData((old) => ({ ...old, loading: false }));
      handleNetworkError(error, history);
    }
  };

  const getClientSaldoData = async () => {
    let newClientPasadas = {};
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await api.get("/api/siryc/saldosPorConcesion", {
        headers: privHeaders(),
      });
      if (!response) {
        notifyWarning("Error en el servidor al traer los datos");
      }
      response.data.guayas.forEach((client) => {
        newClientPasadas[client.clienteId] = [];
      });

      setData((old) => ({
        ...old,
        client: response.data.guayas,
        clientAloag: response.data.aloag,
        clientPasadas: newClientPasadas,
        loading: false,
      }));
    } catch (error) {
      setData((old) => ({ ...old, loading: false }));
      handleNetworkError(error, history);
      if (
        error.response &&
        error.response.data.status === "USUARIO_NO_ENCONTRADO"
      ) {
        setAlertMessage(true);
        setData((old) => ({ ...old, loading: false }));
      }
    }
  };

  const getClientMultipleAccountSaldoData = async (e) => {
    e.preventDefault();

    const clientId = e.target.value;
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await api.get(
        `/api/siryc/saldosMultipleCuentas?userId=${clientId}`,
        {
          headers: privHeaders(),
        }
      );

      if (!response) {
        notifyWarning("Error en el servidor al traer los datos");
      }
      setData((old) => ({
        ...old,
        client: response.data.guayas,
        loading: false,
      }));
    } catch (error) {
      handleNetworkError(error, history);
      setData((old) => ({ ...old, loading: false }));
      if (
        error.response &&
        error.response.data.status === "USUARIO_NO_ENCONTRADO"
      ) {
        setAlertMessage(true);
      }
    }
  };

  const consultPasses = async (clientId, conces) => {
    let newClientPasadas = { ...data.clientPasadas };

    if (calculateDays(initDate, finalDate) > 32) {
      notifyWarning(
        "La busqueda máxima es de 30 días. Por favor intente con menos días",
        {
          autoClose: 4000,
        }
      );
      return;
    }
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await api.get(
        `/api/siryc/pasadasPorConcesion?clienteId=${clientId}&fechaInicio=${initDate}&fechaFin=${finalDate}&concesion=${conces}`,
        {
          headers: privHeaders(),
        }
      );

      if (!response.data.data) {
        notifyWarning("No registra pasadas en las fechas seleccionadas", {
          autoClose: 4000,
        });
        setData((old) => ({ ...old, loading: false }));
        return;
      }

      if (
        response.data.data.mensaje === "NO SE ENCUENTRAN DATOS PARA LA CONSULTA"
      ) {
        notifyWarning("No registra pasadas en las fechas seleccionadas", {
          autoClose: 4000,
        });
        setData((old) => ({ ...old, loading: false }));
        return;
      }

      if (response.data.data.success === 0) {
        notifyWarning(
          "Supero el límite de búsqueda, por favor intente con menos días.",
          {
            autoClose: 4000,
          }
        );
        setData((old) => ({ ...old, loading: false }));
        return;
      }

      newClientPasadas[clientId] = response.data.data.concesiones;

      setData((old) => ({
        ...old,
        clientPasadas: newClientPasadas,
        clientPasadasById: newClientPasadas[clientId],
        concesion: conces,
        loading: false,
      }));
    } catch (error) {
      setData((old) => ({ ...old, loading: false }));
      handleNetworkError(error, history);
      notifyWarning("Hubo un problema. Por favor, reinténtelo");
    }
  };

  const onClear = () => {
    setData((old) => ({
      ...old,
      clientPasadas: [],
      clientPasadasById: [],
      concesion: "",
    }));
  };

  const _handleOnExport = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data.clientPasadasById);
    // console.log(data.clientPasadasById);

    XLSX.utils.book_append_sheet(wb, ws, "Reporte de pasadas");
    XLSX.utils.sheet_add_aoa(ws, [["CATEGORÍA", "VÍA", "NOMBRE DEL PEAJE"]], {
      origin: "H1",
    });

    XLSX.writeFile(wb, "Reporte pasadas.xlsx");
  };

  return (
    <>
      {data.loading ? <TheLoader /> : null}
      {alertMessage ? (
        <AlertMessage
          text={
            "Su usuario no tiene cuentas en los peajes de Concegua, Conorte, El Pan o Alóag, por esto no puede ver su saldo o pasadas. Estamos trabajando para agregar más peajes."
          }
        />
      ) : null}
      <div>
        <label className="page-top-title">Consulta de saldo y pasadas</label>
        <ToastContainer />

        {data.clientMultipleAccounts.length < 3 ? (
          <div>
            {data.client.map((client, i) => {
              return (
                <div key={i}>
                  <h2>Guayas</h2>
                  <div>
                    <h5>{client.cliente}</h5>
                    <Row className="mb-4 balances-container">
                      {Object.keys(client.saldos).map((concesion, index) => {
                        return (
                          <Col key={index} md="3" lg="3">
                            <ConsultCard
                              concesion={concesion}
                              client={client}
                            />
                          </Col>
                        );
                      })}

                      <Col md="12">
                        <div className="text-center">
                          <div className="mt-3">
                            <h4 style={{ color: "var(--blueTG)" }}>
                              Consultar pasadas
                            </h4>
                            <Row style={{ justifyContent: "center" }}>
                              <Col md="3" sm="12" xs="9">
                                <FormGroup>
                                  <Label>
                                    <strong>Concesión</strong>
                                  </Label>
                                  <Input
                                    type="select"
                                    value={concesion}
                                    onChange={(e) =>
                                      setConcesion(e.target.value)
                                    }
                                    required
                                  >
                                    <option value="conorte">Conorte</option>
                                    <option value="concegua">Concegua</option>
                                    <option value="elPan">El Pan</option>
                                  </Input>
                                  <img
                                    src={
                                      concesion === "conorte"
                                        ? conorte
                                        : concesion === "concegua"
                                        ? concegua
                                        : conorte
                                    }
                                    alt="concecion conorte"
                                    className="tolls-img"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="4" sm="12" xs="9">
                                <FormGroup>
                                  <Label>
                                    <strong>Desde</strong>
                                  </Label>
                                  <Input
                                    type="date"
                                    value={initDate}
                                    onChange={(e) =>
                                      setInitDate(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="4" sm="9" xs="9">
                                <FormGroup>
                                  <Label>
                                    <strong>Hasta</strong>
                                  </Label>
                                  <Input
                                    type="date"
                                    value={finalDate}
                                    onChange={(e) =>
                                      setFinalDate(e.target.value)
                                    }
                                    max={moment(
                                      new Date(initDate).setDate(
                                        new Date(initDate).getDate() + 30
                                      )
                                    ).format("YYYY-MM-DD")}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row
                              style={{ justifyContent: "center", gap: "10px" }}
                            >
                              <FormGroup>
                                <button
                                  className="btonbilletera bton-yellow bton-sm"
                                  onClick={() =>
                                    consultPasses(client.clienteId, concesion)
                                  }
                                >
                                  Consultar
                                </button>
                              </FormGroup>
                              <FormGroup>
                                <button
                                  className="btonbilletera bton-sm bton-white"
                                  onClick={() => onClear()}
                                >
                                  Limpiar
                                </button>
                              </FormGroup>
                              {data.clientPasadasById &&
                              data.clientPasadasById.length > 0 ? (
                                <Label>
                                  <strong>Exportar a Excel</strong>
                                  <span>
                                    <img
                                      src={excelLogo}
                                      height={50}
                                      onClick={_handleOnExport}
                                      alt="exportar en excel"
                                    />
                                  </span>
                                </Label>
                              ) : null}
                            </Row>
                          </div>

                          {data.clientPasadas[client.clienteId] &&
                          data.clientPasadas[client.clienteId].length > 0 ? (
                            <div
                              style={{
                                overflow: "auto",
                                margin: "20px",
                              }}
                            >
                              {data.concesion !== "aloag" && (
                                <ConsultTable
                                  clientPasadas={data.clientPasadas}
                                  client={client}
                                />
                              )}
                            </div>
                          ) : (
                            <div>
                              <h4>No registra pasadas</h4>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            })}

            {data.clientAloag.map((client, i) => {
              return (
                <div key={i}>
                  <h2>Alóag</h2>
                  <div>
                    <h5>{client.cliente}</h5>
                    <Row className="mb-4 balances-container">
                      {Object.keys(client.saldos).map((concesion, index) => {
                        return (
                          <Col key={index} md="3" lg="3">
                            <ConsultCard
                              concesion={concesion}
                              client={client}
                            />
                          </Col>
                        );
                      })}
                      <Col md="12">
                        <div className="text-center">
                          <div className="mt-3">
                            <h4 style={{ color: "var(--blueTG)" }}>
                              Consultar pasadas
                            </h4>
                            <Row style={{ justifyContent: "center" }}>
                              <Col md="4" sm="12" xs="9">
                                <FormGroup>
                                  <Label>
                                    <strong>Concesión</strong>
                                  </Label>
                                  <Input
                                    type="select"
                                    value={concesion}
                                    onChange={(e) =>
                                      setConcesion(e.target.value)
                                    }
                                    required
                                  >
                                    <option value="aloag">Alóag</option>
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col md="4" sm="12" xs="9">
                                <FormGroup>
                                  <Label>
                                    <strong>Desde</strong>
                                  </Label>
                                  <Input
                                    type="date"
                                    value={initDate}
                                    onChange={(e) =>
                                      setInitDate(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="4" sm="9" xs="9">
                                <FormGroup>
                                  <Label>
                                    <strong>Hasta</strong>
                                  </Label>
                                  <Input
                                    type="date"
                                    value={finalDate}
                                    onChange={(e) =>
                                      setFinalDate(e.target.value)
                                    }
                                    max={moment(
                                      new Date(initDate).setDate(
                                        new Date(initDate).getDate() + 30
                                      )
                                    ).format("YYYY-MM-DD")}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row
                              style={{ justifyContent: "center", gap: "10px" }}
                            >
                              <FormGroup>
                                <button
                                  className="btonbilletera bton-yellow bton-sm"
                                  onClick={() =>
                                    consultPasses(client.clienteId, "aloag")
                                  }
                                >
                                  Consultar
                                </button>
                              </FormGroup>
                              <FormGroup>
                                <button
                                  className="btonbilletera bton-sm bton-white"
                                  onClick={() => onClear()}
                                >
                                  Limpiar
                                </button>
                              </FormGroup>
                              {data.clientPasadasById &&
                              data.clientPasadasById.length > 0 ? (
                                <Label>
                                  <strong>Exportar a Excel nuevo</strong>
                                  <span>
                                    <img
                                      src={excelLogo}
                                      height={50}
                                      onClick={_handleOnExport}
                                      alt="exportar en excel"
                                    />
                                  </span>
                                </Label>
                              ) : null}
                            </Row>
                          </div>
                          {data.clientPasadas[client.clienteId] &&
                          data.clientPasadas[client.clienteId].length > 0 ? (
                            <div
                              style={{
                                overflow: "auto",
                                margin: "20px",
                              }}
                            >
                              {data.concesion === "aloag" && (
                                <ConsultTable
                                  clientPasadas={data.clientPasadas}
                                  client={client}
                                />
                              )}
                            </div>
                          ) : (
                            <div>
                              <h4>No registra pasadas</h4>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <FormGroup>
              <Input
                type="select"
                id="tipo"
                onChange={getClientMultipleAccountSaldoData}
                required
              >
                <option>Seleccione...</option>
                {data.clientMultipleAccounts.map((accounts) => {
                  return (
                    <option
                      key={accounts.CLIENTE_IDEN}
                      value={accounts.ID_CLIENTE}
                    >
                      {accounts.APELLIDO}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            {data.client.length >= 1 ? (
              <div>
                <Row className="mb-4 balances-container">
                  <Col md="3" lg="3">
                    <ConsultCard concesion="concegua" client={data.client[0]} />
                  </Col>

                  <Col md="3" lg="3">
                    <ConsultCard concesion="conorte" client={data.client[0]} />
                  </Col>

                  <Col md="3" lg="3">
                    <ConsultCard concesion="elPan" client={data.client[0]} />
                  </Col>
                  <Col md="12">
                    <div className="text-center">
                      <div className="mt-3">
                        <h4 style={{ color: "var(--blueTG)" }}>
                          Consultar pasadas
                        </h4>
                        <Row style={{ justifyContent: "center" }}>
                          <Col md="3" sm="12" xs="9">
                            <FormGroup>
                              <Label>
                                <strong>Concesión</strong>
                              </Label>
                              <Input
                                type="select"
                                value={concesion}
                                onChange={(e) => setConcesion(e.target.value)}
                                required
                              >
                                <option value="conorte">Conorte</option>
                                <option value="concegua">Concegua</option>
                                <option value="elPan">El Pan</option>
                              </Input>
                              <img
                                src={
                                  concesion === "conorte"
                                    ? conorte
                                    : concesion === "concegua"
                                    ? concegua
                                    : conorte
                                }
                                alt="concecion concegua"
                                className="tolls-img"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4" sm="12" xs="9">
                            <FormGroup>
                              <Label>
                                <strong>Desde</strong>
                              </Label>
                              <Input
                                type="date"
                                value={initDate}
                                onChange={(e) => setInitDate(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4" sm="9" xs="9">
                            <FormGroup>
                              <Label>
                                <strong>Hasta</strong>
                              </Label>
                              <Input
                                type="date"
                                value={finalDate}
                                onChange={(e) => setFinalDate(e.target.value)}
                                max={moment(
                                  new Date(initDate).setDate(
                                    new Date(initDate).getDate() + 30
                                  )
                                ).format("YYYY-MM-DD")}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                          <Col sm="12" md="4">
                            <FormGroup>
                              <button
                                className="btonbilletera bton-yellow bton-sm"
                                onClick={() =>
                                  consultPasses(
                                    data.client[0].clienteId,
                                    concesion
                                  )
                                }
                              >
                                Consultar
                              </button>
                            </FormGroup>
                          </Col>
                          {data.clientPasadasById &&
                          data.clientPasadasById.length > 0 ? (
                            <Col md="4" sm="12">
                              <Label>
                                <strong>Exportar a Excel</strong>
                                <span>
                                  <img
                                    src={excelLogo}
                                    height={50}
                                    onClick={_handleOnExport}
                                    alt="exportar excel"
                                  />
                                </span>
                              </Label>
                            </Col>
                          ) : null}
                        </Row>
                      </div>

                      {data.clientPasadas[data.client[0].clienteId] &&
                      data.clientPasadas[data.client[0].clienteId].length >
                        0 ? (
                        <div
                          style={{
                            overflow: "auto",
                            margin: "20px",
                          }}
                        >
                          <ConsultTable
                            clientPasadas={data.clientPasadas}
                            client={data.client[0]}
                          />
                        </div>
                      ) : (
                        <div>
                          <h4>No registra pasadas</h4>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            ) : alertMessage ? (
              <AlertMessage
                text={
                  "Su usuario no tiene cuentas en los peajes de Concegua, Conorte, El Pan o Alóag, por esto no puede ver su saldo o pasadas. Estamos trabajando para agregar más peajes. Si usted si tiene una cuenta en alguna de estas concesiones por favor llene los datos en la opción mi cuenta para que pueda acceder a todos nuestros servicios."
                }
              />
            ) : null}
          </div>
        )}
      </div>
      <WhatssapButton />
    </>
  );
};

export default ConsultaMacro;
