import oficinaTag from "../Assets/oficinaTag.jpeg";
import puntoInter from "../Assets/puntoIntervalles.png";
import peajeAloag from "../Assets/peajeAloag.jpeg";
import peajeSantoDomingo from "../Assets/peajeSantoDomingo.jpeg";
import peajeLosAngeles from "../Assets/losangeles.jpeg";
import phamarcy from "../Assets/pharmacysLogo2.jpg";
import cruzazul from "../Assets/cruz_azul.jpg";
import tia from "../Assets/tia.png";

export const concesiones = {
  concegua: "Concegua",
  conorte: "Conorte",
  elPan: "El Pan",
  aloag: "Alóag",
};

export const typeTransaction = (type) => {
  switch (type) {
    case "D":
      return "Débito";
    case "A":
      return "Anulado";
    case "R":
      return "Reposición";
    case "C":
      return "Crédito";
    default:
      return "-";
  }
};

export const itemsPointOfSale = [
  {
    src: `${oficinaTag}`,
    altText: "Rumiñahui",
    caption: "En el peaje de la autopista",
    header: "AUTOPISTA GENERAL RUMIÑAHUI",
  },
  {
    src: `${puntoInter}`,
    altText: "Intervalles",
    caption: "Avenida Intervalles, Peaje Intervalles",
    header: "PEAJE INTERVALLES",
  },
  {
    src: `${peajeAloag}`,
    altText: "Peaje Aloaj",
    caption: "Vía Alóag Santo Domingo, Peaje Alóag",
    header: "PEAJE ALÓAG",
  },
  {
    src: `${peajeSantoDomingo}`,
    altText: "Cóngoma ",
    caption: "Vía Santo Domingo - Buena Fé",
    header: "PEAJE CÓNGOMA",
  },
  {
    src: `${peajeLosAngeles}`,
    altText: "Los Angeles",
    caption: "Los Ríos, Peaje Los Ángeles",
    header: "PEAJE LOS ÁNGELES",
  },
  {
    src: `${phamarcy}`,
    altText: "Farmacia",
    caption: "Encuentranos en Quito y Guayaquil",
    header: "PHARMACY'S",
  },
  {
    src: `${cruzazul}`,
    altText: "Cruz Azul",
    caption:
      "Aplica solo para la Ciudad de Guayaquil, los puedes encontrar en Milagro, El Triunfo, Naranjal, Yaguachi y Daule",
    header: "CRUZ AZUL",
  },
  {
    src: `${tia}`,
    altText: "Cruz Azul",
    caption: "Aplica solo para la Ciudad de Guayaquil",
    header: "TÍA",
  },
];
