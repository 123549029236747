import { Fragment, useRef, useState } from "react";
import { ArrowLeft } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import UploadFile from "./uploadFile";
import {
  _updateHomologacion,
  _updateFileHomologacion,
} from "../../../../../Controllers/homologacionCtl";
import { handleNetworkError } from "../../../../../utils/utils";
import { notifyWarning } from "../../../../Misc/InfoItems/toasts";

function EditHomologacion({ selectHomologacion, onBackTo, updateData }) {
  const history = useHistory();

  const [chkEmpresa, setChkEmpresa] = useState(false);
  const selectRef = useRef(false);
  const [state, setState] = useState({
    reload: false,
    loading: false,
  });

  const cedulaAnversoState = useState({
    filepreview: selectHomologacion.cedula_anverso,
    newFile: null,
    edit: false,
  });
  const [, setCedulaAnverso] = cedulaAnversoState;

  const cedulaReversoState = useState({
    filepreview: selectHomologacion.cedula_reverso,
    newFile: null,
    edit: false,
  });
  const [, setCedulaReverso] = cedulaReversoState;

  const matriculaAnversoState = useState({
    filepreview: selectHomologacion.matricula_anverso,
    newFile: null,
    edit: false,
  });
  const [, setMatriculaAnverso] = matriculaAnversoState;

  const matriculaReversoState = useState({
    filepreview: selectHomologacion.matricula_reverso,
    newFile: null,
    edit: false,
  });
  const [, setMatriculaReverso] = matriculaReversoState;

  const vehiculoState = useState({
    filepreview: selectHomologacion.foto_placa,
    newFile: null,
    edit: false,
  });
  const [, setVehiculo] = vehiculoState;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const _onSubmit = async (data) => {
    const dataTemp = {
      ...data,
      es_empresa: data.checkCompany,
      concesiones: data.concesiones.map((item) => item.value),
    };
    try {
      const { message, data } = await _updateHomologacion(
        selectHomologacion.id_homologacion,
        dataTemp
      );
      updateData(data, selectHomologacion.id_homologacion);
      notifyWarning(message, {
        autoClose: 3000,
      });
    } catch (error) {
      handleNetworkError(error, history);
    }
  };

  const checkCompany = (e) => {
    if (e.target.checked) {
      setChkEmpresa(!chkEmpresa);
      selectRef.current = true;
    } else {
      setChkEmpresa(!chkEmpresa);
      selectRef.current = false;
    }
  };

  const datosPersonales = [
    {
      name: "nombres",
      placeholder: "Nombres",
      requerid: "Los nombres es requerido",
      isSelect: false,
      valueDefault: selectHomologacion.nombres,
    },
    {
      name: "apellidos",
      placeholder: "Apellidos",
      requerid: "Los apellidos es requerido",
      isSelect: false,
      valueDefault: selectHomologacion.apellidos,
    },
    {
      name: "razon_social",
      placeholder: "Razon social",
      requerid: "La razon social es requerido",
      isSelect: false,
      valueDefault: selectHomologacion.razon_social,
    },
    {
      name: "tipo_identificacion",
      placeholder: "Tipo identificación",
      requerid: "El tipo identificación es requerido",
      isSelect: true,
      valueDefault: selectHomologacion.tipo_identificacion,

      options: [
        {
          value: "",
          label: "Seleccione",
        },
        {
          value: "RUC",
          label: "RUC",
        },
        {
          value: "CEDULA",
          label: "CÉDULA",
        },
      ],
    },
    {
      name: "identificacion",
      placeholder: "identificación",
      requerid: "La identificación es requerido",
      isSelect: false,
      valueDefault: selectHomologacion.identificacion,
    },
    {
      name: "correo",
      placeholder: "Correo",
      requerid: "El correo es requerido",
      isSelect: false,
      valueDefault: selectHomologacion.correo,
    },
    {
      name: "direccion",
      placeholder: "Dirección",
      requerid: "La dirección es requerido",
      isSelect: false,
      valueDefault: selectHomologacion.direccion,
    },
    {
      name: "telefono",
      placeholder: "Teléfono",
      requerid: "El teléfono es requerido",
      isSelect: false,
      valueDefault: selectHomologacion.telefono,
    },
  ];

  const datosVehiculo = [
    {
      name: "marca",
      placeholder: "Marca",
      requerid: "La marca es requerido",
      isSelect: false,
      valueDefault: selectHomologacion.marca,
    },
    {
      name: "modelo",
      placeholder: "Modelo",
      requerid: "El modelo es requerido",
      isSelect: false,
      valueDefault: selectHomologacion.modelo,
    },
    {
      name: "placa",
      placeholder: "Placa",
      requerid: "La placa es requerido",
      isSelect: false,
      valueDefault: selectHomologacion.placa,
    },
    {
      name: "categoria",
      placeholder: "Categoría",
      requerid: "La categoría es requerido",
      isSelect: true,
      valueDefault: selectHomologacion.categoria,

      options: [
        {
          value: "",
          label: "Seleccione",
        },
        {
          value: "CATEGORIA 1",
          label: "CATEGORIA 1",
        },
        {
          value: "CATEGORIA 2",
          label: "CATEGORIA 2",
        },
        {
          value: "CATEGORIA 3",
          label: "CATEGORIA 3",
        },
        {
          value: "CATEGORIA 4",
          label: "CATEGORIA 4",
        },
        {
          value: "CATEGORIA 5",
          label: "CATEGORIA 5",
        },
        {
          value: "CATEGORIA 6",
          label: "CATEGORIA 6",
        },
      ],
    },
    {
      name: "color",
      placeholder: "Color",
      requerid: "El color es requerido",
      isSelect: false,
      valueDefault: selectHomologacion.color,
    },
    {
      name: "ejes",
      placeholder: "Ejes",
      requerid: "El ejes es requerido",
      isSelect: false,
      valueDefault: selectHomologacion.ejes,
    },
  ];

  const sendFile = async (file, directory, setStateFile, inputRef) => {
    setState((old) => ({ ...old, loading: true }));
    const formData = new FormData();
    formData.append("directory", directory);
    formData.append("img", file);

    try {
      const { data, message } = await _updateFileHomologacion(
        selectHomologacion.id_homologacion,
        formData
      );
      updateData(data, selectHomologacion.id_homologacion);
      notifyWarning(message, {
        autoClose: 3000,
      });
      setState((old) => ({ ...old, loading: false }));
      setStateFile((old) => ({ ...old, edit: false }));
      inputRef.current.value = null;
    } catch (error) {
      setState((old) => ({ ...old, loading: false }));
      handleNetworkError(error, history);
      if (error.response.data.message) {
        notifyWarning(error.response.data.message);
        return;
      }
    }
  };

  const options = [
    { value: "aloa", label: "Aloa" },
    { value: "consegua", label: "Consegua" },
    { value: "conorte", label: "Conorte" },
    { value: "el pan", label: "El pan" },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          marginBottom: 20,
        }}
      >
        <ArrowLeft onClick={onBackTo} style={{ cursor: "pointer" }} />
        <h3
          style={{
            margin: 0,
          }}
        >
          Detalle de la homologación
        </h3>
      </div>
      <div className="edit-container">
        <div
          className="simple-card-profile mt-4"
          style={{ height: "fit-content" }}
        >
          <Form onSubmit={handleSubmit(_onSubmit)}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Tag</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Tag"
                    disabled
                    defaultValue={selectHomologacion.numero_tag}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Concesiones
                    <span className="text-danger"> *</span>
                  </Label>
                  <Controller
                    name="concesiones"
                    control={control}
                    defaultValue={selectHomologacion.concesiones}
                    render={({ field }) => (
                      <Select
                        isMulti
                        {...field}
                        options={options}
                        defaultValue={selectHomologacion.concesiones}
                        isDisabled={selectHomologacion.status !== "EDITAR"}
                      />
                    )}
                  />
                  <small className="text-danger">
                    {errors.concesiones && errors.concesiones.message}
                  </small>
                </FormGroup>
              </Col>
            </Row>
            <div className="custom-control custom-switch mb-2">
              <input
                type="checkbox"
                className="custom-control-input"
                {...register("checkCompany", { onchange: checkCompany })}
                id="checkCompany"
              />
              <label htmlFor="checkCompany" className="custom-control-label">
                <strong>Empresa</strong>
              </label>
            </div>
            <Row>
              <Col md="6">
                <h2>Datos personales</h2>
                {datosPersonales.map((item, index) => (
                  <Fragment key={index}>
                    {item.isSelect ? (
                      <FormGroup>
                        <Label>
                          {item.placeholder}
                          <span className="text-danger"> *</span>
                        </Label>
                        <select
                          className="form-control"
                          id={item.name}
                          {...register(item.name, {
                            required: true,
                          })}
                          defaultValue={item.valueDefault}
                          disabled={selectHomologacion.status !== "EDITAR"}
                        >
                          {item.options.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <small className="text-danger">
                          {errors[item.name] && errors[item.name].message}
                        </small>
                      </FormGroup>
                    ) : (
                      <FormGroup>
                        <Label>
                          {item.placeholder}
                          <span className="text-danger"> *</span>
                        </Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={item.placeholder}
                          {...register(item.name, {
                            required: {
                              value: true,
                              message: item.requerid,
                            },
                          })}
                          defaultValue={item.valueDefault}
                          disabled={selectHomologacion.status !== "EDITAR"}
                        />
                        <small className="text-danger">
                          {errors[item.name] && errors[item.name].message}
                        </small>
                      </FormGroup>
                    )}
                  </Fragment>
                ))}
              </Col>
              <Col md="6">
                <h2>Datos del vehículo</h2>
                {datosVehiculo.map((item, index) => (
                  <Fragment key={index}>
                    {item.isSelect ? (
                      <FormGroup>
                        <Label>
                          {item.placeholder}
                          <span className="text-danger"> *</span>
                        </Label>
                        <select
                          className="form-control"
                          id={item.name}
                          {...register(item.name, {
                            required: true,
                          })}
                          defaultValue={item.valueDefault}
                          disabled={selectHomologacion.status !== "EDITAR"}
                        >
                          {item.options.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <small className="text-danger">
                          {errors[item.name] && errors[item.name].message}
                        </small>
                      </FormGroup>
                    ) : (
                      <FormGroup>
                        <Label>
                          {item.placeholder}
                          <span className="text-danger"> *</span>
                        </Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={item.placeholder}
                          {...register(item.name, {
                            required: {
                              value: true,
                              message: item.requerid,
                            },
                          })}
                          defaultValue={item.valueDefault}
                          disabled={selectHomologacion.status !== "EDITAR"}
                        />
                        <small className="text-danger">
                          {errors[item.name] && errors[item.name].message}
                        </small>
                      </FormGroup>
                    )}
                  </Fragment>
                ))}
              </Col>
            </Row>
            {selectHomologacion.status === "EDITAR" && (
              <button
                style={{ cursor: "pointer" }}
                className="bton bton-yellow bton-sm mt-2"
                id="registrar"
              >
                Reenviar solicitud de homologación
              </button>
            )}
          </Form>
        </div>
        <div className="simple-card-profile mt-4">
          <Row>
            <Col md="12">
              <h2>Imagenes</h2>
              {selectHomologacion.status === "EDITAR" ? (
                <>
                  <UploadFile
                    dataState={cedulaAnversoState}
                    title="Cédula Anverso"
                    fileDefault={{
                      filepreview: selectHomologacion.cedula_anverso,
                      newFile: null,
                      edit: false,
                    }}
                    updateFile={(file, inputRef) =>
                      sendFile(
                        file.newFile,
                        "cedula_anverso",
                        setCedulaAnverso,
                        inputRef
                      )
                    }
                    loadingButton={state.loading}
                  />
                  <UploadFile
                    dataState={cedulaReversoState}
                    title="Cédula Reverso"
                    fileDefault={{
                      filepreview: selectHomologacion.cedula_reverso,
                      newFile: null,
                      edit: false,
                    }}
                    updateFile={(file, inputRef) =>
                      sendFile(
                        file.newFile,
                        "cedula_reverso",
                        setCedulaReverso,
                        inputRef
                      )
                    }
                  />
                  <UploadFile
                    dataState={matriculaAnversoState}
                    title="Matrícula Anverso"
                    fileDefault={{
                      filepreview: selectHomologacion.matricula_anverso,
                      newFile: null,
                      edit: false,
                    }}
                    updateFile={(file, inputRef) =>
                      sendFile(
                        file.newFile,
                        "matricula_anverso",
                        setMatriculaAnverso,
                        inputRef
                      )
                    }
                  />
                  <UploadFile
                    dataState={matriculaReversoState}
                    title="Matrícula Reverso"
                    fileDefault={{
                      filepreview: selectHomologacion.matricula_reverso,
                      newFile: null,
                      edit: false,
                    }}
                    updateFile={(file, inputRef) =>
                      sendFile(
                        file.newFile,
                        "matricula_reverso",
                        setMatriculaReverso,
                        inputRef
                      )
                    }
                  />
                  <UploadFile
                    dataState={vehiculoState}
                    title="Placa (Foto frontal del vehículo)"
                    fileDefault={{
                      filepreview: selectHomologacion.foto_placa,
                      newFile: null,
                      edit: false,
                    }}
                    updateFile={(file, inputRef) =>
                      sendFile(
                        file.newFile,
                        "foto_placa",
                        setVehiculo,
                        inputRef
                      )
                    }
                  />
                </>
              ) : (
                <>
                  <div className="file-preview">
                    <Label>Cédula Anverso</Label>
                    <img
                      src={selectHomologacion.cedula_anverso}
                      alt="Cédula Anverso"
                    />
                  </div>
                  <div className="file-preview">
                    <Label>Cédula Reverso</Label>
                    <img
                      src={selectHomologacion.cedula_reverso}
                      alt="Cédula Reverso"
                    />
                  </div>
                  <div className="file-preview">
                    <Label>Matrícula Anverso</Label>
                    <img
                      src={selectHomologacion.matricula_anverso}
                      alt="Matrícula Anverso"
                    />
                  </div>
                  <div className="file-preview">
                    <Label>Matrícula Reverso</Label>
                    <img
                      src={selectHomologacion.matricula_reverso}
                      alt="Matrícula Reverso"
                    />
                  </div>
                  <div className="file-preview">
                    <Label>Placa (Foto frontal del vehículo)</Label>
                    <img
                      src={selectHomologacion.foto_placa}
                      alt="Placa (Foto frontal del vehículo)"
                    />
                  </div>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
export default EditHomologacion;
