import React, { useEffect, useState } from 'react';
import { useUserContext } from "../Context/UserContext";
import { privHeaders } from './utils';
import api from '../services/api';
import { Redirect } from 'react-router-dom';
import TheLoader from '../Components/Misc/TheLoader';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const context = useUserContext();
  const [showLoader, setShowLoader] = useState(true);
  const [alreadyCalledApi, setAlreadyCalledApi] = useState(false); // Hack to keep verifyTokenAndRender from rendering more than once

  useEffect(() => {
    function verifyTokenAndUpdateContext() {
      api.post("/api/auth", {}, {
        headers: privHeaders(),
      }).then(response => {
        if (response.data.status === "autorizado_ok") {
          context.updateUser(response.data.data.u);
          context.updateIsLogged(true);
          setShowLoader(false);
        }
        else {
          throw new Error();
        }
      }).catch(e => {
        localStorage.removeItem('t');
        context.updateIsLogged(false);
        context.updateUser(null);
        setShowLoader(false);
      });
    }

    if (!alreadyCalledApi) {
      setAlreadyCalledApi(true);
      verifyTokenAndUpdateContext();
    }
  }, [context, alreadyCalledApi]);

  if (showLoader) {
    return <TheLoader />;
  }

  return context.isLogged ?
    <Component {...rest} /> : <Redirect to='/login' />;
}

export default ProtectedRoute;