import React, { useEffect, useState } from "react";
import logoTaxExpress from "../../../Assets/logo-sin-frase.png";
import { Link } from "react-router-dom";
import "./nav.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import rightArrow from "../../../Assets/right_arrow.png";

const NavLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  // const currentURL = useLocation().pathname;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Used to change navbar's color
  function handleScroll() {
    if (window.scrollY > 20) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  return (
    <Navbar
      id="nav"
      className={`navbar-fixed-top ${isScrolled ? "navScrolled" : ""
        } navbar-light`}
      expand="md"
    >
      <Link to="/">
        <img
          className="logoNav centerLogo"
          src={logoTaxExpress}
          alt="TagExpress"
        />
      </Link>
      <NavbarToggler
        onClick={toggle}
        style={{ background: "rgb(229, 223, 223)" }}
      />
      <Collapse
        isOpen={isOpen}
        navbar
        className={`${isOpen ? "navDropdown" : ""}`}
      >
        <Nav className="mr-auto ml-3" navbar>
          <NavItem>
            <NavLink href="/#puntosVentas" className="hov-link">
              Puntos de ventas
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink href="/#nosotros" className="hov-link">
              Nosotros
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/#contacto" className="hov-link">
              Contacto
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink href="#wallet" className="hov-link">
              Wallet
            </NavLink>
          </NavItem> */}
          <NavItem>
            <Link to="/fq" className="hov-link">
              <label className="hov-link nav-link">Preguntas frecuentes</label>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/tarifas" className="hov-link">
              <label className="hov-link nav-link">Tarifas</label>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/estado-vias" className="hov-link">
              <label className="hov-link nav-link">Estado de vías</label>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/login" className="hov-link">
              <label className="hov-link nav-link">Consulta de saldo</label>
            </Link>
          </NavItem>
        </Nav>
        <div className="loginNavItem">
          <Link to="/login">
            <div className="btn-access">
              <strong style={{ marginRight: 5 }}>Acceso</strong>
              <img src={rightArrow} alt="arrow" height={11} />
            </div>
          </Link>
        </div>
      </Collapse>
    </Navbar>
  );
};

export default NavLayout;
