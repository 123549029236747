import { useRef } from "react";
import { FormGroup, Input, Label } from "reactstrap";

function UploadFile({
  dataState,
  title,
  fileDefault,
  updateFile,
  loadingButton,
}) {
  const inputRef = useRef(null);
  const [state, setState] = dataState;

  const resetFileInput = () => {
    inputRef.current.value = null;
    setState(fileDefault);
  };

  return (
    <div>
      <FormGroup>
        <Label>{title}</Label>
        <Input
          innerRef={inputRef}
          value={state.newFile?.fileName}
          onChange={(event) => {
            const fileObj = event.target.files && event.target.files[0];
            if (!fileObj) {
              return;
            }

            setState({
              newFile: event.target.files[0],
              filepreview: URL.createObjectURL(event.target.files[0]),
              edit: true,
            });
          }}
          type="file"
          id="matricula_anverso"
          accept=".png, .jpg, .jpeg"
        />
        <div className="file-preview">
          <img src={state.filepreview} alt="Cédula Anverso" />
        </div>
        {state.edit && (
          <div style={{ display: "flex", gap: 10 }}>
            {loadingButton ? (
              <>Actualizando la imagen</>
            ) : (
              <>
                <button
                  style={{ cursor: "pointer" }}
                  className="bton bton-white bton-sm mt-2"
                  onClick={resetFileInput}
                >
                  Cancelar
                </button>
                <button
                  style={{ cursor: "pointer" }}
                  className="bton bton-yellow bton-sm mt-2"
                  id="registrar"
                  onClick={() => updateFile(state, inputRef)}
                >
                  Editar foto
                </button>
              </>
            )}
          </div>
        )}
      </FormGroup>
    </div>
  );
}
export default UploadFile;
