import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import api from "../../../../services/api";
import { pubHeaders, handleNetworkError } from "../../../../utils/utils";
// import { validNatural, validNoNatural } from "../../../../utils/validators";
import { notifyWarning } from "../../../Misc/InfoItems/toasts";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import "./register.css";
import { Form, FormGroup, Modal, ModalHeader, ModalBody } from "reactstrap";

const RegisterForm = ({ history, toggle, modal, setModal }) => {
  const [captcha, setCaptcha] = useState(null);
  const [chkEmpresa, setChkEmpresa] = useState(false);
  const selectRef = useRef(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const _onSubmit = async (data) => {
    data.captcha = captcha;
    if (await chkUser(data.correo)) {
      return;
    }
    if (await chkIdentificacion(data.identificacion)) {
      return;
    }
    if (checkConfirmPassword(data.password, data.confirmPassword)) {
      return;
    }
    try {
      await api.post(
        "/api/usuarios",
        { data },
        {
          headers: pubHeaders(),
        }
      );
      let successMsgTime = 15000;
      notifyWarning(
        "Su usuario se creó de manera correcta. Se envió un correo de confirmación. Revise su bandeja de entrada o correo no deseado. El correo de confirmación puede demorar hasta 5 minutos",
        {
          autoClose: successMsgTime,
        }
      );
      setTimeout(() => {
        setModal(false);
      }, successMsgTime);
    } catch (error) {
      handleNetworkError(error, history);
      if (error.response && error.response.data.data === "CAPTCHA_INVALIDO") {
        notifyWarning(
          "Captcha inválido. Vuelva a marcar el captcha y presione el botón 'Registrar' otra vez"
        );
      } else {
        notifyWarning(`${error.response.data.message}`);
      }
    }
  };

  const checkPassworValid = (password) => {
    let passwordValidate =
      /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/.test(password);
    if (passwordValidate) {
      document.getElementById("messagepasslength").style.display = "none";
    } else {
      document.getElementById("messagepasslength").innerHTML =
        "La contraseña debe tener un mínimo de 12 caracteres, una letra mayúscula, un número y un caracter especial";
    }
  };

  //Confirm password
  const checkConfirmPassword = (password, confirmPassword) => {
    console.log(password, confirmPassword);
    if (password !== confirmPassword) {
      document.getElementById("messagePasswordDontMatch").innerHTML =
        "Las contraseñas no coinciden";
      return true;
    } else {
      document.getElementById("messagePasswordDontMatch").style.display =
        "none";
      return false;
    }
  };

  const checkRepeated = async (tipo, valor) => {
    try {
      const response = await api.get(
        `/api/usuarios/chk?tipo=${tipo}&valor=${valor}`,
        {
          headers: pubHeaders(),
        }
      );
      return response.data.repetido;
    } catch (error) {
      handleNetworkError(error, history);
    }
  };

  const checkCompany = (e) => {
    if (e.target.checked) {
      setChkEmpresa(!chkEmpresa);
      selectRef.current = true;
    } else {
      setChkEmpresa(!chkEmpresa);
      selectRef.current = false;
    }
  };

  const chkUser = async (user) => {
    if (await checkRepeated("usuario", user)) {
      notifyWarning(
        "Este correo ya está registrado. Por favor intente con otro"
      );
      return true;
    } else {
      return false;
    }
  };

  const chkIdentificacion = async (identificacion) => {
    try {
      if (await checkRepeated("identificacion", identificacion)) {
        notifyWarning(
          "Este número de identificación ya está registrado. Por favor intente con otro",
          { autoClose: 5000 }
        );
        return true;
      }
    } catch (error) {
      handleNetworkError(error, history);
    }
  };

  return (
    <>
      <div className="login-modal">
        <Modal isOpen={modal} toggle={toggle}>
          <div className="login-card-title">
            <ModalHeader toggle={toggle} style={{ backgroundColor: "#FBC600" }}>
              <strong>CREAR CUENTA</strong>
            </ModalHeader>
          </div>
          <ModalBody className="login-modal-form">
            <Form onSubmit={handleSubmit(_onSubmit)}>
              <div className="custom-control custom-switch mb-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  {...register("checkCompany", { onchange: checkCompany })}
                  id="checkCompany"
                />
                <label htmlFor="checkCompany" className="custom-control-label">
                  <strong>Empresa</strong>
                </label>
              </div>
              <FormGroup>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Correo"
                  {...register("correo", {
                    required: { value: true, message: "Es requerido" },
                    onBlur: (e) => chkUser(e.target.value),
                  })}
                />
                <small className="text-danger">
                  {errors.correo && errors.correo.message}
                </small>
              </FormGroup>

              <FormGroup>
                <input
                  className="form-control"
                  type="password"
                  id="password"
                  placeholder="Contraseña"
                  {...register("password", {
                    required: true,
                    onChange: (e) => checkPassworValid(e.target.value),
                    min: 12,
                    max: 30,
                  })}
                />
                <div className="mb-3">
                  <small
                    style={{ color: "red" }}
                    id="messagepasslength"
                  ></small>
                </div>
                <small className="mb-3">
                  {errors.password && (
                    <span className="text-danger">
                      El campo de contraseña es obligatorio
                    </span>
                  )}
                </small>
              </FormGroup>
              <FormGroup>
                <input
                  className="form-control"
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirmar contraseña"
                  {...register("confirmPassword", {
                    required: true,
                    min: 12,
                    max: 30,
                  })}
                />
                <div className="mb-3">
                  <div className="mb-3">
                    <span
                      style={{ color: "red" }}
                      id="messagePasswordDontMatch"
                    ></span>
                  </div>
                  <small>
                    {errors.password && (
                      <span className="text-danger">
                        El campo de confirmar contraseña es obligatorio
                      </span>
                    )}
                  </small>
                </div>
              </FormGroup>
              <FormGroup>
                <select
                  className="form-control"
                  id="tipo"
                  {...register("tipoIdentificacion", {
                    required: true,
                  })}
                >
                  <option value="">Seleccione</option>
                  <option value="1">RUC</option>
                  <option value="2">CÉDULA</option>
                </select>
                <small>
                  {errors.tipoIdentificacion && (
                    <span className="text-danger">
                      El campo de tipo identificacion es requerido
                    </span>
                  )}
                </small>
              </FormGroup>
              <FormGroup>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Identificación"
                  id="identificacion"
                  {...register("identificacion", {
                    required: true,
                  })}
                />
                <small>
                  {errors.identificacion && (
                    <span className="text-danger">
                      El campo de identificacion es requerido
                    </span>
                  )}
                </small>
              </FormGroup>
              <FormGroup>
                <input
                  className="form-control"
                  maxLength="12"
                  type="text"
                  placeholder="Número de télefono celular"
                  id="telefono"
                  {...register("telefono", {
                    required: true,
                  })}
                />
                <small>
                  {errors.telefono && (
                    <span className="text-danger">
                      El campo de teléfono es requerido
                    </span>
                  )}
                </small>
              </FormGroup>
              <div className="text-center">
                <div className="mt-2">
                  <ReCAPTCHA
                    sitekey="6LfbeCMaAAAAADnjaUax7uRC8MbN41iHqNDeXKAw"
                    onChange={(val) => setCaptcha(val)}
                  />
                </div>
                <button
                  style={{ cursor: "pointer" }}
                  className="bton bton-yellow bton-sm mt-2"
                  id="registrar"
                >
                  Registrar
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default RegisterForm;
