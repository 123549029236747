import React from "react";
import './SideIconCard.css'
const SideIconCard = ({ img, checked, title, content }) => {
    return (
        <div className="te-card" style={{ gap: '20px' }}>
            <div className="te-icon">
                <img src={img} alt="certifications" />
            </div>
            <div className="te-body">
                <h3 className="subtitle mt-2">{title}</h3>
                <p className="paragraph mb-2">{content}</p>
                <div className="check-cert">
                    <img src={checked} alt="certifications checked" />
                </div>
            </div>
        </div>
    )
}

export default SideIconCard;