import { Table } from "reactstrap";
import Categoria1 from "../../../../../Assets/svg/tarifa/Categoria 1.svg";
import Categoria2 from "../../../../../Assets/svg/tarifa/Categoria 2.svg";
import Categoria3 from "../../../../../Assets/svg/tarifa/Categoria 3.svg";
import Categoria4 from "../../../../../Assets/svg/tarifa/Categoria 4.svg";
import Categoria5 from "../../../../../Assets/svg/tarifa/Categoria 5.svg";
import Categoria6 from "../../../../../Assets/svg/tarifa/Categoria 6.svg";

import "./TableRate.css";

function TableRate() {
  const data = [
    {
      urlImg: Categoria1,
      category: 1,
      description:
        "Automóviles, Camperos, Camionetas, Motocarros y Microbuses con ejes de llanta sencilla",
      ejes: 2,
      peaje1: 1.0,
      peaje2: 1.5,
    },
    {
      urlImg: Categoria2,
      category: 2,
      description:
        "Buses, Busetas, Microbuses con eje trasero de doble llantay Camiones de dos ejes.",
      ejes: 2,
      peaje1: 2.0,
      peaje2: 3.0,
    },
    {
      urlImg: Categoria3,
      category: 3,
      description: "Vehículos de pasajeros y de carga de tres ejes.",
      ejes: 3,
      peaje1: 3.0,
      peaje2: 4.5,
    },
    {
      urlImg: Categoria4,
      category: 4,
      description: "Vehículos de carga de cuatro ejes",
      ejes: 4,
      peaje1: 4.0,
      peaje2: 4.5,
    },
    {
      urlImg: Categoria5,
      category: 5,
      description: "Vehículos de carga de cinco ejes",
      ejes: 5,
      peaje1: 5.0,
      peaje2: 6.0,
    },
    {
      urlImg: Categoria6,
      category: 6,
      description: "Vehículos de carga de seis o mas ejes",
      ejes: "6 o mas",
      peaje1: 6.0,
      peaje2: 6.0,
    },
  ];
  return (
    <Table hover responsive striped>
      <thead>
        <tr>
          <th></th>
          <th style={{ width: "35%" }}>Categorías</th>
          <th className="text-center">Ejes</th>
          <th className="text-center">
            Peajes <br /> <strong>Conorte - Consegua - Alóag</strong>
          </th>
          <th className="text-center">
            Peajes <br /> <strong>Pan</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <th scope="row">
              <img
                src={item.urlImg}
                alt="Categoria 1"
                className="img-category"
              />
            </th>
            <td>
              <strong>Categoría {item.category}</strong> <br />
              {item.description}
            </td>
            <td className="text-center">{item.ejes}</td>
            <td className="text-center">${item.peaje1.toFixed(2)}</td>
            <td className="text-center">${item.peaje2.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
export default TableRate;
